import { FC } from "react";
import { HiLocationMarker, HiFlag, HiArrowDown, HiCamera, HiChat, HiCheck, HiPlay, HiSparkles, HiUser, HiDocumentText, HiExclamation, HiChip } from "react-icons/hi";
import { TimelineRow } from "../../../components/TimelineRow";
import { isFunctionCall } from "../types";

const EVENT_TYPES_TO_DISPLAY = {
    "ai.request.start": { title: "Text Generation Start", icon: HiPlay },
    "ai.request.start.stream": { title: "Text Generation Stream Start", icon: HiArrowDown },
    "ai.request.finish": { title: "Text Generation Finish", icon: HiCheck },
    "user.feedback.track": { title: "User Feedback", icon: HiChat },
    "user.interaction.track": { title: "User Interaction", icon: HiUser },
    "demo.image": { title: "Image Generated", icon: HiCamera },
    "user.goal.success": { title: "User Met Goal", icon: HiSparkles },
    "ai.request": { title: "Text Generation", icon: HiDocumentText }
}

interface Props {
    startDatetime: string;
    endDatetime: string;
    events: any[];
}

export const JourneySummaryTimeline: FC<Props> = ({ startDatetime, endDatetime, events }) => {
    return <>
        <TimelineRow logo={HiLocationMarker} title={"Journey Start"} date={startDatetime} color={"gray.500"} index={0} arrLength={events.length + 2} />
        {
            events.map((event: any, index: number) => {
                const color = event.eventType === "ai.request" && event.finishData.aiResponseErrorData !== "null" ? "red.500" : "gray.500";
                // @ts-ignore
                let icon = event.eventType === "ai.request" && event.finishData.aiResponseErrorData !== "null" ? HiExclamation : EVENT_TYPES_TO_DISPLAY[event.eventType].icon;
                let titleOverride;
                let eventId = event.eventId;
                switch (event.eventType) {
                    case "user.interaction.track":
                        if (JSON.parse(event.context)?.before) {
                            titleOverride = "User Edited Text"
                        }
                        break;
                    case "user.goal.success":
                        const goalName = JSON.parse(event.context)?.goal_name;
                        titleOverride = `User Met Goal: ${goalName}`
                        break;
                    case "ai.request":
                        eventId = event.startData.eventId;
                        if (isFunctionCall(event.finishData)) {
                            titleOverride = "Text Generation (Function Call)"
                            icon = HiChip
                        }
                        break;
                }

                return <TimelineRow key={"timeline-row-" + index}
                    logo={icon} title={
                        // @ts-ignore
                        titleOverride ?? EVENT_TYPES_TO_DISPLAY[event.eventType].title
                    }
                    date={new Intl.DateTimeFormat('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: true
                    }).format(new Date(event._time))}
                    color={color}
                    index={index + 1}
                    arrLength={events.length + 2}
                    hash={eventId} />
            })
        }
        <TimelineRow logo={HiFlag} title={"Journey Finish"} date={endDatetime} color={"gray.500"} index={events.length + 1} arrLength={events.length + 2} />
    </>
}