import { Card, CardHeader, CardBody, Text } from "@chakra-ui/react";
// Not sure why types aren't showing up here:
// https://github.com/hustcc/echarts-for-react/blob/master/src/types.ts
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { FC } from "react";

export interface QueryProps {
    environment?: string;
    promptName?: string;
}

interface Props {
    title: string;
    chartOptions: any;
    loading?: boolean;
    description?: string;
}

// Chakra inspired theme.
echarts.registerTheme('rosnik', {
    color: ['#9F7AEA', '#0BC5EA', '#4299E1', '#48BB78', '#ECC94B', '#ED8936', '#ED64A6']
});

export const BaseChart: FC<Props> = ({ title, chartOptions, loading, description }) => {
    return <Card h={"100%"}>
        <CardHeader pb={0}>
            <Text fontSize="xl" as="strong">{title}</Text>
            {description && <Text fontSize="sm">{description}</Text>}

        </CardHeader>
        <CardBody>
            <ReactECharts notMerge={true} option={chartOptions} theme="rosnik" showLoading={loading} />
        </CardBody>
    </Card>
}