export interface TimeRange {
    start: number;  // timestamp
    end: number;    // timestamp
    readable: string;
    relativeKey?: string;
}


const unitMapReverse: { [key: string]: string } = {
    'minutes': 'm',
    'hours': 'h',
    'days': 'd',
    'weeks': 'w',
    'months': 'mo',
    'years': 'y'
};

const relativeUnitToKey = (unit?: string): string | undefined => {
    if (!unit) {
        return undefined;
    }
    return unitMapReverse[unit.toLowerCase()];
}

/**
 * Generate a TimeRange object based on user-input as best as we can.
 * TODO: date ranges don't work.
 * TODO: maybe use https://gist.github.com/MaxGabriel/3b56aa8766b64281541587cd35ee04df
 * @param input user input string
 * @returns TimeRange object on success, null if we can't match it
 */
export function parseDateTimeInput(input: string): TimeRange | null {
    const now = new Date();

    function adjustDate(date: Date, unit: string, quantity: number): Date {
        const unitLower = unit.toLowerCase();
        const newDate = new Date(date);

        switch (unitLower) {
            case 'minutes':
                newDate.setMinutes(date.getMinutes() + quantity);
                break;
            case 'hours':
                newDate.setHours(date.getHours() + quantity);
                break;
            case 'days':
                newDate.setDate(date.getDate() + quantity);
                break;
            case 'weeks':
                newDate.setDate(date.getDate() + (7 * quantity));
                break;
            case 'months':
                newDate.setMonth(date.getMonth() + quantity);
                break;
            case 'years':
                newDate.setFullYear(date.getFullYear() + quantity);
                break;
        }
        return newDate;
    }

    // For the "past X unit" or "last X unit" format.
    let relativeMatch = input.match(/^(past|last) (\d+) (hours?|minutes?|days?|weeks?|months?|years?)$/i);
    if (relativeMatch) {
        const quantity = parseInt(relativeMatch[2] as string, 10);
        // @ts-ignore -- TODO: fix this
        let unit: string = relativeMatch[3] as string;

        // adjustDate expects plurals
        if (!unit.endsWith('s')) {
            unit = unit + 's'; // add the "s" if it's not singular
        }

        const start = adjustDate(now, unit as string, -quantity);
        const ret: TimeRange = {
            start: start.getTime(),
            end: now.getTime(),
            readable: `Past ${quantity} ${unit}`,
            relativeKey: `${quantity}${relativeUnitToKey(unit)}`
        };
        return ret
    }


    let match = input.match(/^(\d+)([mhdwMoy]| minutes| hours| days| weeks| months| years)$/i);
    if (match) {
        const quantity = parseInt(match[1] as string, 10);
        let unit = match[2];

        const unitMap: { [key: string]: string } = {
            'm': 'minutes',
            'h': 'hours',
            'd': 'days',
            'w': 'weeks',
            'mo': 'months',
            'y': 'years'
        };
        unit = unitMap[unit as string] || unit;

        const start = adjustDate(now, unit as string, -quantity);
        const ret: TimeRange = {
            start: start.getTime(),
            end: now.getTime(),
            readable: `Past ${quantity} ${unit}`,
            relativeKey: `${quantity}${relativeUnitToKey(unit)}`
        };
        return ret
    }


    // Map for commonly used relative time descriptions
    const relativeMap: { [key: string]: string } = {
        'hour': 'hours',
        'yesterday': 'days',
        'week': 'weeks',
        'month': 'months',
        'quarter': 'months',
        'year': 'years'
    };

    for (let key in relativeMap) {
        if (input.includes(key)) {
            const quantity = key === 'quarter' ? 3 : 1;
            const start = adjustDate(now, relativeMap[key] as string, -quantity);
            const ret: TimeRange = {
                start: start.getTime(),
                end: now.getTime(),
                readable: `Past ${quantity} ${relativeMap[key]}`,
                relativeKey: `${quantity}${relativeUnitToKey(relativeMap[key])}`
            };
            return ret
        }
    }

    // TODO: this might not work
    // Check for specific dates (e.g., "2023-10-21")
    if (input.includes('-')) {
        const parts = input.split('-');
        if (parts.length === 3) {
            const date = new Date(input);
            if (date.toString() !== 'Invalid Date') {
                const ret = {
                    start: date.setHours(0, 0, 0, 0),
                    end: date.setHours(23, 59, 59, 999),
                    readable: `${date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}`
                };
                return ret
            }
        }
    }


    // TODO: this might not work
    // Check for Unix timestamps or ranges of timestamps
    if (input.includes('-')) {
        let timestamps = input.split('-').map(ts => parseInt(ts.trim()));
        if (timestamps.length === 2
            && typeof timestamps[0] === 'number' && !isNaN(timestamps[0])
            && typeof timestamps[1] === 'number' && !isNaN(timestamps[1])) {
            const ret = {
                start: timestamps[0],
                end: timestamps[1],
                readable: `${new Date(timestamps[0]).toLocaleString()} - ${new Date(timestamps[1]).toLocaleString()}`
            };
            return ret
        }
    }

    // Check for specific periods of a day
    // TODO: this might not work
    const periodMap: { [key: string]: [number, number, number, number] } = {
        'morning': [6, 0, 11, 59],
        'afternoon': [12, 0, 17, 59],
        'evening': [18, 0, 21, 59],
        'night': [22, 0, 5, 59]
    };
    for (let period in periodMap) {
        if (input.includes(period)) {
            const periodTimes = periodMap[period];

            if (!periodTimes) {
                continue;  // If it's undefined for some reason, skip to the next iteration.
            }
            const [startHour, startMinute, endHour, endMinute] = periodTimes;

            const startDate = new Date(now);
            const endDate = new Date(now);
            startDate.setHours(startHour, startMinute, 0, 0);
            endDate.setHours(endHour, endMinute, 59, 999);
            const ret = {
                start: startDate.getTime(),
                end: endDate.getTime(),
                readable: period.charAt(0).toUpperCase() + period.slice(1)
            };
            return ret
        }
    }

    // Check for specific dates with month and day only (e.g., "Oct 1")
    // TODO: this might not work
    let monthDayMatch = input.match(/^(\bJan\b|\bFeb\b|\bMar\b|\bApr\b|\bMay\b|\bJun\b|\bJul\b|\bAug\b|\bSep\b|\bOct\b|\bNov\b|\bDec\b) (\d{1,2})$/i);
    if (monthDayMatch) {
        const monthMap: { [key: string]: number } = {
            'Jan': 0, 'Feb': 1, 'Mar': 2, 'Apr': 3, 'May': 4, 'Jun': 5,
            'Jul': 6, 'Aug': 7, 'Sep': 8, 'Oct': 9, 'Nov': 10, 'Dec': 11
        };

        const monthIndex = monthMap[monthDayMatch[1] as string];
        const day = parseInt(monthDayMatch[2] as string, 10);
        const currentYear = now.getFullYear();

        const startDate = new Date(currentYear, monthIndex as number, day, 0, 0, 0, 0);  // Starts at the beginning of the day
        const endDate = new Date(currentYear, monthIndex as number, day, 23, 59, 59, 999);  // Ends at the end of the day

        const ret = {
            start: startDate.getTime(),
            end: endDate.getTime(),
            readable: `${startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}`
        };
        return ret
    }

    // Check for date ranges with month and days only (e.g., "Oct 1 - 2")
    // TODO: this might not work
    let monthDayRangeMatch = input.match(/^(\bJan\b|\bFeb\b|\bMar\b|\bApr\b|\bMay\b|\bJun\b|\bJul\b|\bAug\b|\bSep\b|\bOct\b|\bNov\b|\bDec\b) (\d{1,2}) - (\d{1,2})$/i);
    if (monthDayRangeMatch) {
        const monthMap: { [key: string]: number } = {
            'Jan': 0, 'Feb': 1, 'Mar': 2, 'Apr': 3, 'May': 4, 'Jun': 5,
            'Jul': 6, 'Aug': 7, 'Sep': 8, 'Oct': 9, 'Nov': 10, 'Dec': 11
        };

        const monthIndex = monthMap[monthDayRangeMatch[1] as string];
        const dayStart = parseInt(monthDayRangeMatch[2] as string, 10);
        const dayEnd = parseInt(monthDayRangeMatch[3] as string, 10);
        const currentYear = now.getFullYear();

        const startDate = new Date(currentYear, monthIndex as number, dayStart, 0, 0, 0, 0);  // Starts at the beginning of the day
        const endDate = new Date(currentYear, monthIndex as number, dayEnd, 23, 59, 59, 999);  // Ends at the end of the day

        return {
            start: startDate.getTime(),
            end: endDate.getTime(),
            readable: `${startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} – ${endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}`
        };
    }
    // Check for MM/DD date format (e.g., "10/1")
    // TODO: this might not work
    let mmddMatch = input.match(/^(\d{1,2})\/(\d{1,2})$/);
    if (mmddMatch) {
        const month = parseInt(mmddMatch[1] as string, 10) - 1; // JavaScript month index is zero-based
        const day = parseInt(mmddMatch[2] as string, 10);
        const currentYear = now.getFullYear();

        const startDate = new Date(currentYear, month, day, 0, 0, 0, 0);  // Starts at the beginning of the day
        const endDate = new Date(currentYear, month, day, 23, 59, 59, 999);  // Ends at the end of the day

        return {
            start: startDate.getTime(),
            end: endDate.getTime(),
            readable: startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
        };
    }

    return null
}

// Example usage:
// let input = "3 days";
// console.log(parseDateTimeInput(input));
// console.log(parseDateTimeInput("last 4 weeks"));
// console.log(parseDateTimeInput("past 4 months"));
// console.log(parseDateTimeInput("yesterday"));
// console.log(parseDateTimeInput("45m"));
// console.log(parseDateTimeInput("12 hours"));
// console.log(parseDateTimeInput("10 days"));
// console.log(parseDateTimeInput("2 weeks"));
// console.log(parseDateTimeInput("Oct 1"));
// console.log(parseDateTimeInput("Oct 1 - 2"));
// console.log(parseDateTimeInput("10/1"));
// console.log(parseDateTimeInput("1696143600000 - 1696316399999"));



