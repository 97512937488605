import { useAuth } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import { FC } from "react";
import { calculateTokensCost } from "../utils/tokenCosts";
import { API_BASE_URL } from "../utils/constants";
import { Spinner } from "@chakra-ui/react";

const DashboardPage: FC = () => {
  const { getToken } = useAuth();
  const { isLoading, data } = useQuery({
    queryKey: ["overview"],
    queryFn: () =>
      getToken().then((token) => {
        return fetch(`${API_BASE_URL}/api/explore/overview`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((res) => res.json());
      }),
  });

  return (
    <div className="px-10 items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
      <div>
        <h5 className="dark:text-white">Total Prompts</h5>
        <p className="dark:text-white">
          {isLoading ? <Spinner /> : data.data.total_features}
        </p>
      </div>
      <div>
        <h5 className="dark:text-white">Total Events</h5>
        <p className="dark:text-white">
          {isLoading ? <Spinner /> : data.data.total_events}
        </p>
      </div>
      <div>
        <h5 className="dark:text-white">Total Outlier Events</h5>
        <p className="dark:text-white">
          {isLoading ? <Spinner /> : data.data.total_outlier_events}
        </p>
      </div>
      <div>
        <h5 className="dark:text-white">Total Tokens</h5>
        <p className="dark:text-white">
          {isLoading ? <Spinner /> : data.data.total_tokens}
        </p>
      </div>
      <div>
        <h5 className="dark:text-white">Total Cost</h5>
        <p className="dark:text-white">
          {isLoading ? (
            <Spinner />
          ) : (
            "$" + calculateTokensCost(data.data.total_tokens)
          )}
        </p>
      </div>
      {/* <p>Error: {error}</p> */}
    </div>
  );
};

export default DashboardPage;
