import { Card, CardHeader, HStack, CardBody, VStack, Box, Text, useDisclosure, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, IconButton, Flex, Divider, Image, Link, Tooltip, CardFooter, Tag, Grid, GridItem, useToast } from "@chakra-ui/react";
import { FC, useEffect, useRef, useState } from "react";
import { HiArrowsExpand, HiCode, HiOutlineClipboardCopy, HiOutlineInformationCircle } from "react-icons/hi";
import { AIRequestFinish, AIRequestStart, isFunctionCall } from "../types";
import { diffChars } from 'diff';
import { distance } from "fastest-levenshtein";
import JsonView from "@uiw/react-json-view";
import { TooltipIconButton } from "../../../components/TooltipIconButton";
import React from "react";
import { FunctionCallAccordion } from "./FunctionCallAccordion";

interface SharedProps {
    d: any;
    messages?: { content: string; role: string }[];
    completion?: { content: string; function_call?: string; role: string };
    tags?: { [key: string]: string }[];
    showMessagesInfoTooltip?: boolean;
    footer?: React.ReactNode;
}

interface AGSProps extends SharedProps {
    v: AIRequestStart;
}

interface AGFProps extends SharedProps {
    v: AIRequestFinish;
}


interface CardInfoHeaderProps {
    eventId: string;
    eventTime: number;
    eventTitle: string;
    tags: { [key: string]: string | undefined }[];
    onRawClick?: () => void;
}

const CardInfoHeader: FC<CardInfoHeaderProps> = ({ eventId, eventTime, eventTitle, tags, onRawClick }) => {
    const humanReadableDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
    }).format(new Date(eventTime));
    return <>
        <VStack align={"start"}>
            <HStack justifyContent={"space-between"} w={"full"}>
                <VStack align={"start"} spacing={0} w={"full"}>
                    <HStack justifyContent={"space-between"} w={"full"}>
                        <Link href={"#" + eventId} fontSize={"xs"}>{humanReadableDate}</Link>
                        <HStack>
                            {onRawClick && <TooltipIconButton tooltipText="Show raw payload" onClick={onRawClick} icon={<HiCode />} />}
                        </HStack>
                    </HStack>
                    <Text as={"strong"} fontSize={"lg"}>{eventTitle}</Text>
                </VStack>

            </HStack>

            {/* TODO: maybe this goes in an info tooltip kinda thing? */}
            <HStack wrap={"wrap"}>
                {tags.map((t, i) => {
                    return Object.keys(t).map((k) => {
                        if (!t[k] || t[k] === "null") return null;
                        return <Tag key={`${eventId}-tag-${i}-${k}`}>{k}:{t[k]}</Tag>
                    })
                })}
            </HStack>
        </VStack>
    </>
};

interface MessageProps {
    m: { content: string; function_call?: string; role: string };
    eventId: any;
    i?: number;
    showMessagesInfoTooltip?: boolean;
}

const Message: FC<MessageProps> = ({ m, eventId, i, showMessagesInfoTooltip }) => {
    const renderOptions = ["text", "json", "markdown", "html"];
    const [renderAs, setRenderAs] = useState<string>("text");
    const [show, setShow] = useState<boolean>(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    // useRef is used to get the height of the text element
    const textRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState<boolean>(false);

    // Hack to set isTruncated whenever Chakra decides to truncate the text.
    useEffect(() => {
        // @ts-ignore -- need to set the type of textRef
        if (textRef.current && textRef.current.scrollHeight > textRef.current.clientHeight) {
            setIsTruncated(true);
        } else {
            setIsTruncated(false);
        }
    }, [m.content]);


    return (
        <Box key={`${eventId}-message-${i}`} width={"full"}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
        >
            <HStack justifyContent={"space-between"}>
                <HStack>
                    <Text as={"strong"}>{m.role.toUpperCase()}{m.function_call && " (FUNCTION CALL)"}</Text>
                    {showMessagesInfoTooltip && <Tooltip label='Previously seen messages are available in code view.' fontSize='xs' shouldWrapChildren>
                        <HiOutlineInformationCircle />
                    </Tooltip>}
                    <Box visibility={!show ? "hidden" : "visible"} pt={"1"}>
                        <HStack>
                            {renderOptions.map((r) => {
                                return <Text onClick={() => setRenderAs(r)}
                                    key={`${r}-${i}`}
                                    as={renderAs === r ? "strong" : "span"}
                                    cursor={"pointer"}
                                    fontSize={"xs"}>
                                    {r.toUpperCase()}
                                </Text>
                            })}
                            <TooltipIconButton
                                tooltipText="Copy message to clipboard."
                                icon={<HiOutlineClipboardCopy />}
                                onClick={(event) => {
                                    event.stopPropagation()
                                    let copyText = m.content;
                                    if (typeof m.content === "object") {
                                        copyText = JSON.stringify(m.content)
                                    }
                                    if (m.function_call) {
                                        copyText = JSON.stringify(m.function_call)
                                    }
                                    navigator.clipboard.writeText(copyText)
                                    toast({
                                        title: 'Copied to clipboard.',
                                        description: `Copied message to clipboard.`,
                                        status: 'success',
                                        duration: 1500,
                                        isClosable: true,
                                    })
                                }} />
                        </HStack>
                    </Box>
                </HStack>
                {isTruncated && (
                    <IconButton
                        aria-label="Expand message into a modal"
                        icon={<HiArrowsExpand />}
                        onClick={onOpen}
                        size={"sm"}
                        variant={"ghost"} />
                )}
            </HStack>
            <Box overflowY={"scroll"} maxH={"250px"}>
                {m.content && (
                    typeof m.content === "object" ?
                        <Text>{JSON.stringify(m.content)}</Text> :
                        <Text>{m.content}</Text>
                )}
                {m.function_call && <Text>{JSON.stringify(m.function_call)}</Text>}
            </Box>
            {/* This allows the user to choose the render method for the above content */}
            {isTruncated && (
                <Modal isOpen={isOpen} onClose={onClose} size="full">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalCloseButton />
                        <ModalBody>
                            <Text>{m.content}</Text>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={onClose}>Close</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            )}
        </Box>

    );
};

// TODO: when we have more than N messages, then
// we may want to have a card-level expand button.
const BaseAIGenerationCard: FC<AGSProps | AGFProps> = (props) => {
    const { d, v, messages, completion, tags, showMessagesInfoTooltip, footer } = props;
    const [showRaw, setShowRaw] = useState<boolean>(false);
    const errorMessage = JSON.parse(d.aiResponseErrorData)?.message

    const onRawClick = () => {
        setShowRaw(!showRaw);
    }

    let eventTitle = "notset";
    let rawPayload = {};
    if (v.eventType === "ai.request.start") {
        rawPayload = JSON.parse(d.aiRequestPayload)
        eventTitle = "Generation Start"
    } else if (v.eventType === "ai.request.finish") {
        eventTitle = "Generation Finish"
        if (errorMessage) {
            rawPayload = JSON.parse(d.aiResponseErrorData)
        } else {
            rawPayload = JSON.parse(d.aiResponsePayload)
        }
    }

    return (
        <Card key={d.eventId} w={"full"} id={d.eventId}
            borderWidth={errorMessage ? 1 : 0}
            borderRadius={errorMessage ? "md" : ""}
            borderColor={errorMessage ? "red.300" : ""}>
            <CardHeader pb={0}>
                <CardInfoHeader eventId={d.eventId} eventTime={d._time} eventTitle={eventTitle} tags={tags ?? []} onRawClick={onRawClick} />
            </CardHeader>

            <CardBody pb={0}>
                {showRaw && <Box borderWidth={"1px"} borderRadius={"lg"} p={"3"} overflowY={"scroll"} maxH={"250px"}>
                    <JsonView value={rawPayload} objectSortKeys displayDataTypes={false} shortenTextAfterLength={50} />
                </Box>
                }
                {!showRaw && <VStack alignItems={"start"}>
                    {/* TODO: decide if we show N/M messages */}
                    {/* <Text>Showing {messages?.length}</Text> */}
                    {messages && messages.map((m, i) => <Message key={`${d.eventId}-message-${i}`} m={m} eventId={d.eventId} i={i} showMessagesInfoTooltip={showMessagesInfoTooltip && i === 0} />)}
                    {!errorMessage && completion && <Message m={completion} eventId={d.eventId} />}
                    {errorMessage && <Message m={{ content: errorMessage, role: "error" }} eventId={d.eventId} />}
                </VStack>}
            </CardBody>

            <CardFooter>
                {footer}
            </CardFooter>
        </Card >
    );
};

export const AIGenerationStart: FC<AGSProps> = (props) => <BaseAIGenerationCard {...props} />;

export const AIGenerationFinish: FC<AGFProps> = (props) => <BaseAIGenerationCard {...props} />;

interface AIRequestCardProps {
    startEvent: AIRequestStart;
    finishEvent: AIRequestFinish;
    _time: number;
    inputMessages: { content: string; role: string }[];
    outputMessage: { content: string; function_call?: string; role: string };
    showMessagesInfoTooltip?: boolean;
}

export const AIRequestCard: FC<AIRequestCardProps> = ({ startEvent, finishEvent, _time, inputMessages, outputMessage, showMessagesInfoTooltip }) => {
    const [showRaw, setShowRaw] = useState<boolean>(false);
    const errorMessage = JSON.parse(finishEvent.aiResponseErrorData)?.message

    const onRawClick = () => {
        setShowRaw(!showRaw);
    }

    const eventTitle = isFunctionCall(finishEvent) ? "Text Generation (Function Call)" : "Text Generation";
    const requestPayload = JSON.parse(startEvent.aiRequestPayload)
    const responsePayload = JSON.parse(finishEvent.aiResponsePayload)
    const responseErrorPayload = JSON.parse(finishEvent.aiResponseErrorData)
    const apiUrl = new URL(JSON.parse(startEvent.aiMetadata).openai_attributes.api_base)

    let tags = [
        { model: finishEvent.aiModel },
        { provider: startEvent.aiProvider },
        { temperature: requestPayload?.temperature },
        { endpoint: apiUrl.toString() },
        { "max-tokens": requestPayload?.max_tokens },
        { n: requestPayload?.n },
        { timeout: requestPayload?.timeout },
    ]
    if (startEvent.promptName) {
        // @ts-ignore -- TODO: fix types
        tags.push({ "prompt-name": startEvent.promptName })
    }
    if (!errorMessage) {
        tags.push(...[{
            // @ts-ignore -- TODO: fix types
            duration: Math.floor(finishEvent.aiResponseResponseMs).toString(),
            "total-tokens": responsePayload?.usage?.total_tokens,
            "completion-tokens": responsePayload?.usage?.completion_tokens,
        }])
    } else if (responseErrorPayload) { // Is an error response
        if (responseErrorPayload.json_body) {
            tags.push(...[
                // @ts-ignore -- TODO: fix types
                { "error-code": responseErrorPayload.json_body.error.code },
            ])
        }
    }

    return (
        <Card key={startEvent.eventId} w={"full"} id={startEvent.eventId}
            borderWidth={errorMessage ? 1 : 0}
            borderRadius={errorMessage ? "md" : ""}
            borderColor={errorMessage ? "red.300" : ""}>
            <CardHeader pb={0}>
                <CardInfoHeader eventId={startEvent.eventId} eventTime={_time} eventTitle={eventTitle} tags={tags ?? []} onRawClick={onRawClick} />
            </CardHeader>

            <CardBody pb={0}>
                <Grid templateColumns="repeat(12, 1fr)" gap={3}>
                    <GridItem>
                        <Text as={"strong"} fontSize={"md"}>Input</Text>
                    </GridItem>
                    <GridItem colSpan={11}>
                        <Box borderWidth={"1px"} borderRadius={"lg"} p={"3"} w="full">
                            {showRaw && <Box maxH="250px" overflowY={"scroll"}>
                                <JsonView value={requestPayload} objectSortKeys displayDataTypes={false} shortenTextAfterLength={50} />
                            </Box>}
                            {!showRaw && <VStack alignItems={"start"}>
                                {inputMessages && inputMessages.map((m, i) => <React.Fragment key={`${startEvent.eventId}-message-${i}`}>
                                    <Message m={m} eventId={startEvent.eventId} i={i} showMessagesInfoTooltip={showMessagesInfoTooltip && i === 0} />
                                    {i < inputMessages.length - 1 && <Divider />}
                                </React.Fragment>)}
                            </VStack>}
                        </Box>
                    </GridItem>
                    <GridItem>
                        <Text as={"strong"} fontSize={"md"}>Output</Text>
                    </GridItem>
                    <GridItem colSpan={11}>
                        <Box borderWidth={"1px"} borderRadius={"lg"} p={"3"} w="full">
                            {showRaw && !errorMessage && <Box maxH="250px" overflowY={"scroll"}>
                                <JsonView value={responsePayload} objectSortKeys displayDataTypes={false} shortenTextAfterLength={50} />
                            </Box>}
                            {showRaw && errorMessage && <Box maxH="250px" overflowY={"scroll"}>
                                <JsonView value={responseErrorPayload} objectSortKeys displayDataTypes={false} shortenTextAfterLength={50} />
                            </Box>}
                            {!showRaw && !errorMessage && outputMessage && <Message m={outputMessage} eventId={finishEvent.eventId} />}
                            {!showRaw && errorMessage && <Message m={{ content: errorMessage, role: "error" }} eventId={finishEvent.eventId} />}
                        </Box>
                    </GridItem>
                </Grid>
            </CardBody>

            <CardFooter>
                <FunctionCallAccordion requestPayload={requestPayload} />
            </CardFooter>
        </Card >
    );
};

interface BaseCardProps {
    eventId: string;
    eventTime: number;
    title: string;
    tags?: { [key: string]: string }[];
    cardBody?: React.ReactNode;
}

const BaseCard: FC<BaseCardProps> = ({ eventId, eventTime, title, tags, cardBody }) => {
    return <Card key={`timeline-card-${eventId}`} w={"full"} id={eventId}>
        <CardHeader pb={0}>
            <CardInfoHeader eventId={eventId} eventTime={eventTime} eventTitle={title} tags={tags ?? []} />
        </CardHeader>
        <CardBody>
            {cardBody ?? <></>}
        </CardBody>
    </Card >
}

interface TimelineCardProps {
    event: any;
}

interface StringDiffProps {
    original: string;
    updated: string;
}

const StringDiff: React.FC<StringDiffProps> = ({ original, updated }) => {
    const diffs = diffChars(original, updated);

    return (
        <Flex>
            <VStack flex="1" spacing={2} alignItems="start">
                <Text as={"strong"}>Original</Text>
                <Box borderRight="1px solid gray.300" pr={2} w="100%" maxH={"250px"} overflowY={"scroll"}>
                    {diffs.map((part, index) => (
                        <Text
                            key={index}
                            color={part.removed ? 'red.700' : 'gray.700'}
                            bgColor={part.removed ? 'red.100' : 'transparent'}
                            my={1}
                        >
                            {part.removed || part.added ? "" : part.value}
                        </Text>
                    ))}
                </Box>
            </VStack>
            <VStack flex="1" spacing={2} alignItems="start" pl={2}>
                <Text as={"strong"}>User Edit</Text>
                <Box w="100%" maxH={"250px"} overflowY={"scroll"}>
                    {diffs.map((part, index) => (
                        <Text
                            key={index}
                            color={part.added ? 'green.700' : 'gray.700'}
                            bgColor={part.added ? 'green.100' : 'transparent'}
                            my={1}
                        >
                            {part.value}
                        </Text>
                    ))}
                </Box>
            </VStack>
        </Flex>
    );
};

export const UserInteractionTimelineCard: FC<TimelineCardProps> = ({ event }) => {
    const context = event.context !== "null" ? JSON.parse(event.context) : {}
    let cardBody = <></>
    let title = "User Interaction"
    let editDistanceDsiplay;
    if (context.after) {
        title = "User Edited Text"
        cardBody = <HStack>
            <StringDiff original={context.before} updated={context.after} />
        </HStack>
        const editDistanceScore = distance(context.before, context.after);
        editDistanceDsiplay = (1 - (editDistanceScore / Math.max(context.before.length, context.after.length))) * 100;
    } else if (context.user_input) {
        cardBody = <HStack>
            <Text>{context.user_input}</Text>
        </HStack>
    }

    const tags = []
    if (editDistanceDsiplay) {
        tags.push({
            similarity: `${editDistanceDsiplay.toFixed(2)}%`
        })
    }


    return <BaseCard eventId={event.eventId} eventTime={event._time} title={title} cardBody={cardBody}
        tags={tags}
    />
}

export const UserFeedbackTimelineCard: FC<TimelineCardProps> = ({ event }) => {
    return <BaseCard eventId={event.eventId} eventTime={event._time} title={"User Feedback"}
        cardBody={
            <VStack>
                <Flex alignItems="center" justifyContent="center">
                    {[...Array(11)].map((_, i) => (
                        <Box
                            key={i}
                            mx={1}
                            p={2}
                            borderRadius="md"
                            bg={i === event.userScore ? "blue.500" : "gray.200"}
                            color={i === event.userScore ? "white" : "black"}
                            fontWeight={i === event.userScore ? "bold" : "normal"}
                        >
                            {i}
                        </Box>
                    ))}
                </Flex>
                {event.userOpenResponse && event.userOpenResponse !== "null" && <><Divider /><Text>{event.userOpenResponse}</Text></>}
            </VStack>
        } />
}

export const ImageTimelineCard: FC<TimelineCardProps> = ({ event }) => {
    const imageBase64 = JSON.parse(event.context)?.b64_json
    return <BaseCard eventId={event.eventId} eventTime={event._time} title={"Image Generated"} cardBody={
        <VStack>
            <Image src={`data:image/png;base64,${imageBase64}`} />
        </VStack>
    } />
}

export const UserGoalTimelineCard: FC<TimelineCardProps> = ({ event }) => {
    const goalName = JSON.parse(event.context)?.goal_name
    return <BaseCard eventId={event.eventId} eventTime={event._time} title={`User Met Goal: ${goalName}`}
        cardBody={
            <VStack>
                <Text>&#127881;</Text>
            </VStack>
        } />
}
