import { FC, useEffect, useState } from "react";
import { Box, FormLabel, Grid, GridItem, HStack, Heading, VStack } from "@chakra-ui/react";
import { EventCountChart } from "./components/EventCountChart";
import { FailedRequestChart } from "./components/FailedRequestsChart";
import { LatencyChart } from "./components/LatencyChart";
import { FailedRequestsUrlStatus } from "./components/FailedRequestsUrlStatus";
import { RequestCountChart } from "./components/RequestCountChart";
import { Select } from "chakra-react-select";
import { TopErrorChart } from "./components/TopErrorChart";
import { EnvironmentSelect } from "../components/EnvironmentSelect";
import { useSearchParams } from "react-router-dom";


const UtilizationPage: FC = () => {

    const [pageLoaded, setPageLoaded] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedEnvironment, setSelectedEnvironment] = useState<string>(searchParams.get("environment") ?? "all");


    // Delay rendering until the component is mounted.
    // Otherwise our charts grow way past their Card containers.
    useEffect(() => {
        setPageLoaded(true);
    }, []);

    // This will be null until the component is mounted,
    // so that our charts don't grow past their Card containers.
    if (!pageLoaded) {
        return null;
    }

    const handleEnvironmentChange = (env: string) => {
        setSelectedEnvironment(env);
        setSearchParams({ environment: env })
    }

    return (
        <VStack align={"start"}>
            <HStack justifyContent={"space-between"} w={"full"}>
                <Heading size='sm'>Utilization Overview</Heading>
                <HStack>
                    <EnvironmentSelect defaultEnvironment={searchParams.get("environment")} onChange={handleEnvironmentChange} />
                    <Box>
                        <FormLabel fontSize="xs" fontWeight="medium" mb={0}>Timeframe</FormLabel>
                        <Select
                            defaultValue={{ label: "Past 24 hours", value: "Past 24 hours" }}
                            options={[{
                                label: "Past 24 hours",
                                value: "Past 24 hours"
                            }]}
                            size="sm"
                            chakraStyles={{
                                container: (provided) => ({
                                    ...provided,
                                    w: "200px"
                                }),
                            }}
                            isDisabled
                        />
                    </Box>
                </HStack>
            </HStack>
            <Grid templateColumns='repeat(4, 1fr)' gap={6} w={"full"}>
                <GridItem rowSpan={1} colSpan={4} minWidth="400px">
                    <EventCountChart environment={selectedEnvironment} />
                </GridItem>

                <GridItem rowSpan={1} colSpan={2} minWidth="400px" >
                    <FailedRequestsUrlStatus environment={selectedEnvironment} />
                </GridItem>

                <GridItem rowSpan={1} colSpan={2} minWidth="400px" >
                    <RequestCountChart environment={selectedEnvironment} />
                </GridItem>
                <GridItem rowSpan={1} colSpan={2} minWidth="400px">
                    <FailedRequestChart environment={selectedEnvironment} />
                </GridItem>
                <GridItem rowSpan={1} colSpan={2} minWidth="400px">
                    <TopErrorChart environment={selectedEnvironment} />
                </GridItem>
                <GridItem rowSpan={1} colSpan={4} minWidth="400px">
                    <LatencyChart environment={selectedEnvironment} />
                </GridItem>

            </Grid>
        </VStack>
    );
};

export default UtilizationPage;
