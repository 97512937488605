import { FC, useEffect, useState } from 'react';
import { Select, SingleValue } from 'chakra-react-select';
import { Box, FormControl, FormLabel } from '@chakra-ui/react';
import { useAuthQuery } from '../../../hooks/useAuthQuery';

interface PromptOption {
    label: string;
    value: string;
}

interface Props {
    defaultPromptName?: string | null;
    onChange: (promptName: string) => void;
}

export const PromptNameSelect: FC<Props> = ({ defaultPromptName, onChange }) => {
    const startingPromptName = {
        label: defaultPromptName ?? "All",
        value: defaultPromptName ?? "all",
    }
    const [selectedOption, setSelectedOption] = useState<PromptOption>(startingPromptName);
    const [options, setOptions] = useState<PromptOption[]>([{
        label: "All",
        value: "all",
    }]);

    const { data, isLoading } = useAuthQuery({
        endpoint: "/api/v1/timeseries/prompt-names",
    })

    useEffect(() => {
        if (data?.data) {
            // Extract unique prompt name values from the data and keep existing options
            const rawData = data.data;
            const uniqueEnvironments = rawData
                // @ts-ignore -- TODO
                ? [...new Set(rawData.filter((row) => row.prompt_name !== null).map((row) => row.prompt_name))] as string[]
                : [];

            const newOptions = uniqueEnvironments
                .sort()
                .map((env) => ({ label: env, value: env }));

            // Combine existing options with new unique environment options
            // @ts-ignore -- TODO
            setOptions((currentOptions) => {
                const currentValues = new Set(currentOptions.map((option) => option.value));
                const combinedOptions = [
                    ...currentOptions,
                    // @ts-ignore -- TODO
                    ...newOptions.filter((option) => !currentValues.has(option.value))
                ];

                // Defensively sort the options by label
                return combinedOptions.sort((a, b) => {
                    // Handle null or undefined for a.label
                    if (a.label == null) {
                        return b.label == null ? 0 : 1; // If both are null/undefined, they are equal, otherwise b comes first
                    }
                    // Handle null or undefined for b.label
                    if (b.label == null) {
                        return -1; // a comes first as it's not null/undefined
                    }
                    // If both are not null/undefined, use localeCompare
                    return a.label.localeCompare(b.label);
                });
            });
        }
    }, [data]);

    const handleChange = (newValue: SingleValue<PromptOption>) => {
        if (!newValue) return;
        setSelectedOption({ label: newValue.label, value: newValue.value });
        onChange(newValue.value);
    };

    return (
        <Box>
            <FormControl variant="floating">
                <FormLabel fontSize="xs" fontWeight="medium" mb={0}>Prompt Name</FormLabel>
                <Select
                    options={options}
                    onChange={handleChange}
                    value={selectedOption}
                    size={"sm"}
                    chakraStyles={{
                        // Fit the container to the width of the selected option
                        container: (provided) => ({
                            ...provided,
                            w: "fit-content"
                        }),
                        // Fit the menu to the largest option
                        menu: (base) => ({
                            ...base,
                            width: "max-content",
                            minWidth: "100%"
                        }),
                    }}
                    isLoading={isLoading}
                />

            </FormControl >
        </Box>
    );
};
