import { FC } from "react";
import { useAuthQuery } from "../../../../hooks/useAuthQuery";
import { createStackedSeries } from "../../timeseries";
import { BaseChart, QueryProps } from "../../components/BaseChart";

export const RequestCountChart: FC<QueryProps> = ({ environment }) => {
    const { data, isLoading } = useAuthQuery({
        endpoint: "/api/v1/timeseries",
        queryParams: {
            query: "request-count-by-url",
            environment: environment,
        }
    })

    // console.log(data?.data.length)
    const { series, xAxis, legend } = createStackedSeries({
        data: data?.data || [],
        chartType: 'bar',
    })

    const stackedOptions = {
        legend: legend,
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        animationEasing: 'cubicOut',
        animationDuration: 300,
        dataset: {
            source: series
        },
        xAxis: xAxis,
        yAxis: {
            type: 'value'
        },
        series: series,
        tooltip: {
            trigger: 'axis',
            confine: true,
        },
        itemStyle: {
            borderType: 'solid',
            borderColor: '#73c0de',
            shadowColor: '#5470c6',
            shadowBlur: 3,
        },
    };

    return <BaseChart title="Request Count" chartOptions={stackedOptions} loading={isLoading} />
}