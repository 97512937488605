// Yoinked from https://github.com/horizon-ui/horizon-ui-chakra/blob/main/src/components/dataDispaly/TimelineRow.js#L4
import { Box, Flex, Icon, Link, Text, useColorModeValue } from '@chakra-ui/react';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
    logo: any;
    title: string;
    date: string;
    color: string;
    index: number;
    arrLength: number;
    hash?: string;
}

export const TimelineRow: FC<Props> = (props) => {
    const { logo, title, date, color, index, arrLength, hash } = props;
    const textColor = useColorModeValue('gray.700', 'white.300');
    const bgIconColor = useColorModeValue('white.300', 'gray.700');

    return (
        <Flex alignItems='center' minH='78px' mb='5px'>
            <Flex direction='column' h='100%'>
                <Icon
                    as={logo}
                    bg={bgIconColor}
                    color={color}
                    h={'30px'}
                    w={'26px'}
                    pe='6px'
                    zIndex='1'
                    position='relative'
                    right={document.documentElement.dir === 'rtl' ? '-8px' : ''}
                    left={document.documentElement.dir === 'rtl' ? '' : '-8px'}
                />
                <Box w='2px' bg='gray.200' h={index === arrLength - 1 ? '0px' : '100%'} />
            </Flex>
            <Flex direction='column' h='100%'>
                {hash && <Box w="fit-content"><Link as={RouterLink} to={"#" + hash} fontSize={"sm"} color={textColor} fontWeight={"bold"}>{title}</Link></Box>}
                {!hash &&
                    <Text fontSize='sm' color={textColor} fontWeight='bold'>
                        {title}
                    </Text>
                }
                <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                    {date}
                </Text>
            </Flex>
        </Flex>
    );
}