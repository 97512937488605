import { FC, useEffect } from "react";
import { Outlet } from "react-router";
import { useUser } from "@clerk/clerk-react";
import * as Sentry from "@sentry/react";
import { Rosnik } from "@rosnik/browser-sdk";
import { FullStory } from '@fullstory/browser';

const Root: FC = () => {
  const { user } = useUser();

  useEffect(() => {
    if (import.meta.env.PROD) {
      Sentry.setUser({
        username: user?.id
      })
      FullStory('setProperties', {
        type: 'user',
        properties: {
          displayName: user?.fullName,
          email: user?.primaryEmailAddress,
        },
      });
      // TODO: allow nullable user
      if (user?.id) Rosnik.setUser(user.id)
    }
  }, [user]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default Root;
