
export type UserInteraction = {
    _time: string;
    userId: string;
    eventId: string;
}

export type UserFeedback = {
    // TODO: bug in setting _time in browser-sdk.
    _time: string;
    userId: string;
    userScore: number;
    userOpenResponse: string;
}

export type AIRequestStart = {
    eventId: string;
    eventType: string;
    _time: string;
    aiMetadata: any;
    aiRequestPayload: string;
    // [{"content": "", "role": ""}]
    // aiRequestMessages: { content: string; role: string }[];
    aiRequestMessages: string;
    aiModel: string;
    aiAction: string;
    aiProvider: string;
    aiRequestTemperature: string;
    aiUserInteractionId: string;
    promptName: string | null;
}

export type AIRequestFinish = {
    eventId: string;
    eventType: string;
    _time: string;
    aiMetadata: any;
    aiModel: string;
    aiAction: string;
    aiProvider: string;
    aiUserInteractionId: string;
    // aiResponseMessage: { content: string; role: string };
    aiResponseMessage: string;
    // Ripped from choices[0].text
    aiCompletionText: string;
    aiResponseCompletionTokens: number;
    aiResponsePayload: string;
    // TODO: needs to get on AIRequestStart?
    aiResponsePromptTokens: number;
    aiRequestStatrtId: string;
    // TODO: needs to be a type
    aiResponseErrorData: string;
    promptName: string | null;
    aiResponseResponseMs: number;
}

export const isFunctionCall = (event: AIRequestFinish) => {
    let outputMessage = JSON.parse(event.aiResponseMessage);
    // TODO: move to SQL?
    if (!outputMessage) {
        outputMessage = {
            content: JSON.parse(event.aiResponsePayload)?.choices[0].text,
            role: "assistant"
        }
    }
    return !!outputMessage.function_call
}

export type JourneyMessage = {
    firstEventId: string;
    firstTime: string;
    // String and converts to Message.
    message: string;
}

export type Message = {
    content: string;
    role: string;
}

export type FunctionCall = {
    description: string;
    name: string;
    parameters: {
        properties: any;
        required: string[];
        type: string;
    };
}