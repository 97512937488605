import {
  Box,
  Flex,
  FlexProps,
  HStack,
  Img,
  useMenuButton,
} from "@chakra-ui/react";
import { FC } from "react";
import { HiSelector } from "react-icons/hi";

interface Props {
  orgName: string;
  orgSlug: string;
  orgImageUrl: string;
  isCollapsed: boolean; // This prop indicates if the sidebar is collapsed
}

export const AccountSwitcherButton: FC<Props & FlexProps> = ({
  orgName,
  orgSlug,
  orgImageUrl,
  isCollapsed,
  ...flexProps
}) => {
  const buttonProps = useMenuButton(flexProps);

  return (
    <Flex
      as="button"
      {...buttonProps}
      w="full"
      alignItems="center"
      rounded="lg"
      bg="gray.700"
      px="3"
      py="2"
      fontSize="sm"
      userSelect="none"
      cursor="pointer"
      outline="0"
      transition="all 0.2s"
      _active={{ bg: "gray.600" }}
      _focus={{ shadow: "outline" }}
    >
      <HStack spacing="3" width="100%">
        <Img
          boxSize="8" // Use boxSize for both width and height
          rounded="md"
          objectFit="cover"
          src={orgImageUrl}
          alt={orgName}
        />
        {!isCollapsed && ( // Only render the text if the sidebar is not collapsed
          <Box textAlign="start" flex="1" minW="0">
            <Box noOfLines={1} fontWeight="semibold" isTruncated>
              {orgName}
            </Box>
            <Box fontSize="xs" color="gray.400" isTruncated>
              {orgSlug}
            </Box>
          </Box>
        )}
        {!isCollapsed && (
          <Box ml="auto" fontSize="lg" color="gray.400">
            <HiSelector />
          </Box>
        )}
      </HStack>
    </Flex>
  );
};
