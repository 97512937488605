// ErrorFallback.tsx
import { Button, VStack, Heading, Text, Icon, Card, CardBody } from "@chakra-ui/react";
import { FaSadTear } from "react-icons/fa"; // using a sad icon for the cute factor

interface ErrorFallbackProps {
    resetError: () => void;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ resetError }) => {
    return (
        <Card>
            <CardBody>
                <VStack
                    spacing={4}
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                >
                    <Icon as={FaSadTear} boxSize="50px" color="blue.300" />
                    <Heading size="md" color="gray.600">Oops! Something went wrong.</Heading>
                    <Text fontSize="sm" color="gray.500">Still not working? Ping us in Slack.</Text>
                    <Text fontSize="xs" color="gray.400">(We're probably already on it!)</Text>
                    <Button colorScheme="blue" variant="ghost" size="sm" onClick={resetError}>Try Again</Button>
                </VStack>
            </CardBody>
        </Card>
    );
};

export default ErrorFallback;
