import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { AccountSwitcherButton } from './AccountSwitcherButton';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  email: string;
  activeOrg: {
    name: string;
    slug: string;
    imageUrl: string;
  }
  organizations: {
    id: string;
    name: string;
    slug: string | null;
  }[];
  signOut: () => void;
  setActiveOrg: (options: { orgId: string; orgSlug: string }) => Promise<void>;
  isCollapsed?: boolean;
}

export const AccountSwitcher: FC<Props> = ({ email, activeOrg, organizations, signOut, setActiveOrg, isCollapsed }) => {
  return (
    <Menu>
      <AccountSwitcherButton
        orgName={activeOrg.name}
        orgSlug={activeOrg.slug}
        orgImageUrl={activeOrg.imageUrl}
        isCollapsed={isCollapsed ?? false} />
      <MenuList shadow="lg" py="4" color={useColorModeValue('gray.600', 'gray.200')} px="3">
        {!isCollapsed && ( // Only render the email text if the sidebar is not collapsed
          <Text fontWeight="medium" mb="2" isTruncated>
            {email}
          </Text>
        )}
        <MenuOptionGroup defaultValue={activeOrg.slug}>
          {organizations.map(org => {
            if (org.slug) {
              return <MenuItemOption key={org.slug} value={org.slug} fontWeight="semibold" rounded="md" onClick={async () => {
                // @ts-expect-error -- org.slug is always set for us
                await setActiveOrg({ orgId: org.id, orgSlug: org.slug })
              }}>
                {org.name}
              </MenuItemOption>
            }
            return null // Don't render an option if no slug available
          })}
        </MenuOptionGroup>
        <MenuDivider />
        <MenuItem rounded="md"><Link to={import.meta.env.VITE_ORGANIZATION_SETTINGS_URL}>Organization settings</Link></MenuItem>
        <MenuDivider />
        <MenuItem rounded="md" onClick={signOut}>Logout</MenuItem>
      </MenuList>
    </Menu>
  )
}
