import { useState, type FC, useEffect } from "react";
import { Outlet } from "react-router";
import { Flex, Box, useBreakpointValue } from "@chakra-ui/react";
import Sidebar from "../components/sidebar";
import { ErrorBoundary } from "@sentry/react";
import ErrorFallback from "../components/ErrorFallback";

const NavbarSidebarLayout: FC = function () {
  const handleReset = () => {
    // Handle resetting the state or re-fetching data
    window.location.reload(); // simple approach to refresh the page
  };

  // State to handle the sidebar collapse on mobile views
  const [isMobile, setIsMobile] = useState(false);

  // Determine if we are on a mobile view
  const isMobileView = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    // Collapse the sidebar when on mobile
    setIsMobile(isMobileView ?? false);
  }, [isMobileView]);


  return (
    <Box height="100vh" overflow="hidden" position="relative">
      <Flex h="full" id="app-container">
        <Sidebar isForcedCollapsed={isMobile} />
        {/* @ts-ignore -- not sure what this value should be */}
        <Box w="full" h="full" overflowY={"scroll"} p="6">
          <ErrorBoundary
            fallback={
              <Flex justifyContent={"center"} alignItems={"center"} h="full">
                <ErrorFallback resetError={handleReset} />
              </Flex>
            }
          >
            <Outlet />
          </ErrorBoundary>
        </Box>
      </Flex>
    </Box>
  );
};

export default NavbarSidebarLayout;
