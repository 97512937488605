import { useRouteError } from "react-router-dom";

const ErrorBoundary = () => {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return (
    <div>
      <p>Something went wrong! Try refreshing the page.</p>
      <p>If the problem persists, reach out in your shared Slack channel.</p>
    </div>
  );
};

export default ErrorBoundary;
