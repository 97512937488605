import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import * as Sentry from "@sentry/react";
import { Rosnik } from "@rosnik/browser-sdk";

import { init as initFullStory } from '@fullstory/browser';



if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://818cd44b904ff767f8cc4fdfb7a3120c@o4506119898923008.ingest.sentry.io/4506119908884480",
    integrations: [
      new Sentry.Replay(),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  });

  Rosnik.init({
    apiKey: "sWf2yJeQl16I_dKVRUm0AjU6JApIxK5APoFnG6qBeyM",
    allowedDomains: ["api.rosnik.ai"],
    environment: import.meta.env.VITE_ROSNIK_ENVIRONMENT,
  });

  initFullStory({ orgId: 'o-1S4ZPE-na1' });

}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
