import { FC } from "react";
import { useAuthQuery } from "../../../hooks/useAuthQuery";
import { createSeries } from "../timeseries";
import { QueryProps, BaseChart } from "./BaseChart";

interface Props extends QueryProps {
    timeseriesQuery: string;
    chartName: string;
    chartDescription?: string;
    lineColor?: string;
}

export const TimeseriesLineChart: FC<Props> = ({ environment, promptName, timeseriesQuery, chartName, chartDescription, lineColor }) => {
    const { data, isLoading } = useAuthQuery({
        endpoint: "/api/v1/timeseries",
        queryParams: {
            query: timeseriesQuery,
            environment: environment,
            promptName: promptName,
        }
    })

    const seriesData = createSeries({
        data: data?.data || [],
        chartType: 'line',
        seriesOptions: {
            showSymbol: false,
            tooltip: {
                valueFormatter: function (value: number) {
                    return `${value}%`
                },
            },
            itemStyle: {
                color: lineColor
            }
        },

    })
    const chartOptions = {
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        animationEasing: 'cubicOut',
        animationDuration: 300,
        dataset: {
            source: seriesData
        },
        xAxis: {
            type: 'time',
        },
        yAxis: {
            type: 'value',

        },
        series: seriesData,
        tooltip: {
            trigger: 'axis',
            confine: true,
        }
    };
    return <BaseChart title={chartName} chartOptions={chartOptions} description={chartDescription} loading={isLoading} />

}