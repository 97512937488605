import { Card, CardHeader, CardBody, Text, Table, TableContainer, Th, Thead, Tr, Tbody, Link } from "@chakra-ui/react";
import { FC } from "react";
import { useAuthQuery } from "../../../../hooks/useAuthQuery";
import { Link as ReactRouterLink } from "react-router-dom";
import { QueryProps } from "../../components/BaseChart";

const createTextGenSearchQuery = (str: string) => {
    // Remove JSON-like parts from the string using a regular expression
    const textOnly = str.replace(/{.*}/, '').trim();

    // Split the remaining text by whitespace
    const words = textOnly.split(/\s+/);

    // Take the first four words
    const firstFourWords = words.slice(0, 4).join(' ');
    const encodedWords = encodeURIComponent(firstFourWords);
    // Because encodeURIComponent doesn't encode spaces.
    const plusEncodedWords = encodedWords.replace(/%20/g, '+');

    // TODO: Add support for time ranges
    return `/text-generations?past=1d&q=error+contains+"${plusEncodedWords}"`;
}


export const TopErrorChart: FC<QueryProps> = ({ environment }) => {
    const { data, isLoading } = useAuthQuery({
        endpoint: "/api/v1/timeseries/top-chart",
        queryParams: {
            query: "errors",
            environment: environment,
        }
    })

    return <Card h={"390px"}>
        <CardHeader pb={0}>
            <Text fontSize="xl" as="strong">Top Errors</Text>
            <Text fontSize="sm">Certain errors are grouped together to allow for more unique errors to appear.</Text>
        </CardHeader>
        <CardBody>
            {isLoading && <Text>Loading...</Text>}
            {data && <TableContainer overflowY={"scroll"} maxH={"300px"}>
                <Table size="sm" variant='simple' overflowY={"scroll"}>
                    <Thead>
                        <Tr>
                            <Th>Count</Th>
                            <Th>Value</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data?.data?.map((d: any, i: number) => {
                            return <Tr key={`top-error-${i}`}>
                                <Th>{d.count}</Th>
                                <Th><Link as={ReactRouterLink} to={createTextGenSearchQuery(d.value)}>{d.value}</Link></Th>
                            </Tr>
                        })}
                    </Tbody>
                </Table>
            </TableContainer>}
        </CardBody>
    </Card>
}