import { FC } from "react";
import { useAuthQuery } from "../../../hooks/useAuthQuery";
import { TimeseriesApiData } from "../timeseries";
import { QueryProps, BaseChart } from "./BaseChart";


interface Props extends QueryProps {
    timeseriesQuery: string;
    chartName: string;
    chartDescription?: string;
    barColor?: string;
}


export const TimeseriesBarChart: FC<Props> = ({ environment, promptName, timeseriesQuery, chartName, chartDescription, barColor }) => {
    // TODO: handle errors
    const { data, isLoading } = useAuthQuery({
        endpoint: "/api/v1/timeseries",
        queryParams: {
            query: timeseriesQuery,
            environment: environment,
            promptName: promptName,
        }
    })

    const eventCountOptions = {
        grid: { top: 8, right: 8, bottom: 24, left: 36 },
        animationEasing: 'cubicOut',
        animationDuration: 300,
        xAxis: {
            type: 'time',
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: data?.data?.map((d: TimeseriesApiData) => [d._time, d.value]) || [],
                name: chartName,
                type: 'bar',
                smooth: true,
                itemStyle: {
                    borderRadius: [5, 5, 0, 0], // Specify the border radius
                    borderType: 'solid',
                    color: barColor
                },
            },
        ],
        tooltip: {
            trigger: 'axis',
        },
    };

    return <BaseChart title={chartName} chartOptions={eventCountOptions} loading={isLoading} description={chartDescription} />
}