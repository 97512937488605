import { useAuth } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import { FC, useState } from "react";
import { API_BASE_URL } from "../utils/constants";
import { useSearchParams } from "react-router-dom";
import {
  InputGroup,
  InputLeftAddon,
  Input,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  VStack,
  Text,
} from "@chakra-ui/react";

type RawEvent = {
  eventId: string;
  receivedAt: string;
  data: any;
};

const EventsPage = () => {
  const { orgSlug, getToken } = useAuth();
  // @ts-ignore
  const [searchParams, setSearchParams] = useSearchParams();
  const apiSearchParams = new URLSearchParams(searchParams).toString();
  // @ts-ignore
  const [showDrawer, setShowDrawer] = useState(false);
  // @ts-ignore
  const [drawerContent, setDrawerContent] = useState();

  const { data, isLoading } = useQuery({
    queryKey: [orgSlug, "events", apiSearchParams],
    queryFn: () =>
      getToken().then((token) => {
        return fetch(`${API_BASE_URL}/api/explore/events?${apiSearchParams}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((res) => res.json());
      }),
  });
  const formattedData = data
    ? // @ts-ignore
    data.data.map((d) => ({
      eventId: d.event_id,
      receivedAt: d.publish_time,
      data: JSON.parse(d.data),
    }))
    : [];

  return (
    <VStack>
      {/* Search and filter */}
      <InputGroup>
        <InputLeftAddon children="Search" />
        <Input
          type="text"
          placeholder='prompt:"text" completion:"text" user:"id"'
        />
      </InputGroup>
      <EventsTable events={formattedData} isLoading={isLoading} />
    </VStack>
  );
};

interface EventsTableProps {
  events: RawEvent[];
  isLoading: boolean;
}

const EventsTable: FC<EventsTableProps> = function ({ events, isLoading }) {
  if (isLoading) {
    return (
      <div className="px-4">
        <p>Loading...</p>
      </div>
    );
  }

  if (events.length === 0) {
    return (
      <div className="px-4">
        <p>No results found</p>
      </div>
    );
  }

  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th>Received At</Th>
          <Th>Duration</Th>
          <Th>User</Th>
          <Th>Completion</Th>
        </Tr>
      </Thead>
      <Tbody>
        {events.map((e) => {
          // Hack for demo.
          // Support non-chat model TODO.
          if (!e.data.response.choices[0].message) {
            return;
          }
          const receivedAt = new Date(e.receivedAt);
          const completion = e.data.response.choices[0].message.content;
          return (
            <Tr key={e.eventId}>
              <Td>
                {receivedAt.toLocaleDateString() +
                  " " +
                  receivedAt.toLocaleTimeString()}
              </Td>
              <Td>{(e.data.end_time - e.data.start_time).toFixed(5)}s</Td>
              <Td>{e.data.request.user}</Td>
              <Td>
                <Text noOfLines={1}>{completion}</Text>
                {/* {completion.length > 70
                  ? completion.substr(0, 70) + "..."
                  : completion} */}
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default EventsPage;
