import { RouterProvider } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import NavbarSidebarLayout from "./layouts/navbar-sidebar";
import DashboardPage from "./pages/dashboard";
import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  RedirectToSignIn
} from "@clerk/clerk-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SelectOrganization from "./pages/organizations/organizations";
import Root from "./pages/root";
import RedirectToActiveOrganizationDashboard from "./pages/organizations/activeOrgRedirect";
import React from "react";
import ErrorBoundaryPage from "./pages/error";
import { ChakraProvider } from "@chakra-ui/react";

import { theme as proTheme } from '@chakra-ui/pro-theme';
import { extendTheme, theme as baseTheme } from '@chakra-ui/react';
import '@fontsource-variable/open-sans';
import '@fontsource-variable/spline-sans';

import EventsPage from "./pages/events";
import TimelinePage from "./pages/timeline/page";
import GenerationsPage from "./pages/generations";
import { ComingSoonPage } from "./pages/ComingSoon";
import UtilizationPage from "./pages/overview/utilization/page";
import FeedbackOverviewPage from "./pages/overview/feedback/page";

//pro theme set up
export const theme = extendTheme(
  {
    colors: { ...baseTheme.colors, brand: baseTheme.colors.blue },
  },
  proTheme,
)

if (!import.meta.env.VITE_REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

const clerkPubKey = import.meta.env.VITE_REACT_APP_CLERK_PUBLISHABLE_KEY;

const container = document.getElementById("root");

if (!container) {
  throw new Error("React root element doesn't exist!");
}

const queryClient = new QueryClient();

const singedInRouter = createBrowserRouter([
  {
    id: "root",
    path: "/",
    // No root layout for now
    element: <Root />,
    ErrorBoundary: ErrorBoundaryPage,
    children: [
      {
        id: "RedirectToActiveOrganizationDashboard",
        path: "",
        element: <RedirectToActiveOrganizationDashboard />,
      },
      {
        id: "organizations",
        path: "organizations",
        element: <SelectOrganization />,
      },
      {
        id: "authenticated-root",
        path: "",
        element: <NavbarSidebarLayout />,
        children: [
          {
            id: "dashboard",
            path: "dashboard",
            element: <DashboardPage />,
          },
          {
            id: "utilization",
            path: "utilization",
            element: <UtilizationPage />,
          },
          {
            id: "events",
            path: "events",
            element: <EventsPage />,
          },
          {
            id: "evals",
            path: "evals",
            element: <ComingSoonPage />,
          },
          {
            id: "timeline",
            path: "journey/:journeyId",
            element: <TimelinePage />
          },
          {
            id: "explore-text-generations",
            path: "text-generations",
            element: <GenerationsPage />
          },
          {
            id: "explore-image-generations",
            path: "image-generations",
            element: <ComingSoonPage />
          },
          {
            id: "explore-user-activity",
            path: "user-activity",
            element: <ComingSoonPage />
          },
          {
            id: "overview-utilization",
            path: "overview/utilization",
            element: <UtilizationPage />
          },
          {
            id: "overview-feedback",
            path: "overview/feedback",
            element: <FeedbackOverviewPage />
          }
        ],
      },
    ],
  },
]);

function App() {
  return (
    <React.StrictMode>
      <ClerkProvider publishableKey={clerkPubKey}>
        <QueryClientProvider client={queryClient}>
          <SignedIn>
            <ChakraProvider theme={theme}>
              <RouterProvider router={singedInRouter} />
            </ChakraProvider>
          </SignedIn>
          <SignedOut>
            <RedirectToSignIn />
          </SignedOut>
        </QueryClientProvider>
      </ClerkProvider>
    </React.StrictMode>
  );
}

export default App;
