import { Tooltip, IconButton } from "@chakra-ui/react";
import { FC } from "react";

interface Props {
    tooltipText: string;
    onClick: (event: any) => void;
    icon: JSX.Element;

}

export const TooltipIconButton: FC<Props> = ({ tooltipText, onClick, icon }) => {
    return <Tooltip label={tooltipText} fontSize='xs' shouldWrapChildren>
        <IconButton
            colorScheme={"gray"}
            size={"xs"}
            icon={icon}
            cursor="pointer"
            variant={"ghost"}
            aria-label={tooltipText}
            onClick={onClick} />
    </Tooltip>
}