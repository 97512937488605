import { BaseChart, QueryProps } from "../../components/BaseChart";
import { useAuthQuery } from "../../../../hooks/useAuthQuery";
import { TimeseriesApiData } from "../../timeseries";
import { FC } from "react";

export const FailedRequestChart: FC<QueryProps> = ({ environment }) => {
    // TODO: handle errors
    const { data, isLoading } = useAuthQuery({
        endpoint: "/api/v1/timeseries",
        queryParams: {
            query: "failed-requests",
            environment: environment,
        }
    })

    const failedReqiestOptions = {
        grid: { top: 8, right: 8, bottom: 24, left: 36 },
        animationEasing: 'cubicOut',
        animationDuration: 300,
        xAxis: {
            type: 'time',
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: data?.data?.map((d: TimeseriesApiData) => [d._time, d.value]) || [],
                name: "Failed Requests",
                type: 'bar',
                smooth: true,
                // Per bar styling.
                itemStyle: {
                    borderRadius: [5, 5, 0, 0], // Specify the border radius
                    color: '#F56565',
                    borderType: 'solid',
                },
            },
        ],
        tooltip: {
            trigger: 'axis',
        },

    };

    return <BaseChart title="Failed Requests" chartOptions={failedReqiestOptions} loading={isLoading} />
}