import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { API_BASE_URL } from '../utils/constants';
import { useAuth } from '@clerk/clerk-react';

// Utility function to append query parameters to a URL, using arrow function syntax
const createQueryString = (params: { [key: string]: string }) => {
    const urlParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
        if (value) urlParams.append(key, value);
    });
    return urlParams.toString();
};

interface UseAuthQueryParams {
    endpoint: string;
    queryParams?: { [key: string]: any };
    enabled?: boolean;
}

export const useAuthQuery = ({ endpoint, queryParams = {}, enabled = true }: UseAuthQueryParams) => {
    const { orgSlug, getToken } = useAuth();

    const queryKey = [endpoint, { orgSlug, ...queryParams }];

    return useQuery({
        queryKey: [queryKey, { orgSlug, ...queryParams }],
        queryFn: async ({ queryKey }: QueryFunctionContext) => {
            // Our query keys are made up of the endpoint and then an
            // object containing the org slug and the query params.
            const [, params] = queryKey as [string, { [key: string]: any }];

            // Strip the org slug from the params object, and use the rest.
            const { orgSlug, ...rest } = params
            const queryString = createQueryString({ ...rest });
            const url = `${API_BASE_URL}${endpoint}?${queryString}`;
            const token = await getToken();

            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        },
        enabled,
    });
};
