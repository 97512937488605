import { useState, type FC, useEffect } from "react";
import { useMatches } from "react-router-dom";
import { Flex, Stack, Box, useBreakpointValue } from "@chakra-ui/react";
import { AccountSwitcher } from "./AccountSwitcher";
import {
  useAuth,
  useClerk,
  useOrganization,
  useOrganizationList,
  useUser,
} from "@clerk/clerk-react";
import { NavGroup } from "./NavGroup";
import { ReactRouterNavItem } from "./ReactRouterNavItem";
import { HiChartBar, HiOutlineChevronLeft, HiOutlineChevronRight, HiOutlineClipboardCheck, HiOutlineDocumentText, HiOutlinePhotograph, HiOutlineUser, HiUserGroup } from "react-icons/hi";

interface Props {
  isForcedCollapsed?: boolean;
}

const Sidebar: FC<Props> = function ({ isForcedCollapsed }) {
  const matches = useMatches();
  const renderedMatch = matches[matches.length - 1];

  const clerk = useClerk();
  const { signOut } = useAuth();
  const { user } = useUser();
  const { organization } = useOrganization();
  const { organizationList } = useOrganizationList();


  // State to manage whether the sidebar is collapsed
  const [isCollapsed, setIsCollapsed] = useState(isForcedCollapsed ?? false);

  useEffect(() => {
    if (isForcedCollapsed === undefined) return;
    setIsCollapsed(isForcedCollapsed)
  }, [isForcedCollapsed])

  // Function to toggle sidebar collapse
  const toggleSidebar = () => {
    if (isForcedCollapsed) return;
    setIsCollapsed(!isCollapsed)
  };

  // Responsive style for width
  const sidebarWidth = useBreakpointValue({ base: "20", md: isCollapsed ? "20" : "64" });


  const email = user?.primaryEmailAddress?.toString()
  const activeOrg = organization && organization.slug ? { slug: organization.slug, name: organization.name, imageUrl: organization.imageUrl } : {};
  const organizations = organizationList?.map(orgList => ({
    id: orgList.organization.id,
    name: orgList.organization.name,
    slug: orgList.organization.slug
  })) || [];
  const shouldRenderSwitcher = email && activeOrg.slug && activeOrg.name;

  return (
    <Box w={sidebarWidth} bg="gray.900" color="white" fontSize="sm" flexShrink={0}>
      <Flex h="full" direction="column" px="4" py="4">
        {shouldRenderSwitcher && <AccountSwitcher
          email={email}
          activeOrg={activeOrg}
          organizations={organizations}
          signOut={signOut}
          setActiveOrg={async ({ orgId, orgSlug }) => {
            await clerk.setActive({ organization: orgId })
            // Replace the current subdomain with the new org slug and keep the rest of the path.
            const domain = window.location.hostname;
            if (domain !== "localhost") {
              let path = window.location.pathname;
              if (path === "/") {
                path = "/text-generations"
              }
              const scheme = window.location.protocol;
              const subdomain = domain.split(".")[0];
              const rest = domain.split(".").slice(1).join(".");
              if (subdomain !== orgSlug) {
                window.location.replace(`${scheme}//${orgSlug}.${rest}${path}`);
              }
            }
          }}
          isCollapsed={isCollapsed}
        />}
        <Stack spacing="8" flex="1" overflow="auto" pt="8">
          <NavGroup label={isCollapsed ? "" : "Overview"}>
            <ReactRouterNavItem
              href={`/overview/feedback`}
              active={renderedMatch?.id === "overview-feedback"}
              icon={<HiUserGroup />}
              label="Feedback"
              isCollapsed={isCollapsed}
            />
            <ReactRouterNavItem
              href={`/overview/utilization`}
              active={renderedMatch?.id === "overview-utilization"}
              icon={<HiChartBar />}
              label="Utilization"
              isCollapsed={isCollapsed}
            />
          </NavGroup>
          <NavGroup label={isCollapsed ? "" : "Explore"}>
            {/* <ReactRouterNavItem
              href={`/journeys`}
              active={renderedMatch?.id === "journeys"}
              icon={<BiMap />}
              label="Journeys"
            /> */}
            <ReactRouterNavItem
              href={`/text-generations`}
              active={renderedMatch?.id === "explore-text-generations"}
              icon={<HiOutlineDocumentText />}
              label="Text Generations"
              isCollapsed={isCollapsed}
            />
            <ReactRouterNavItem
              href={`/image-generations`}
              active={renderedMatch?.id === "explore-image-generations"}
              icon={<HiOutlinePhotograph />}
              label="Image Generations"
              isCollapsed={isCollapsed}
            />
            <ReactRouterNavItem
              href={`/user-activity`}
              active={renderedMatch?.id === "explore-user-activity"}
              icon={<HiOutlineUser />}
              label="User Activity"
              isCollapsed={isCollapsed}
            />
            {/* <ReactRouterNavItem
              href={`/events`}
              active={renderedMatch?.id === "events"}
              icon={<BiData />}
              label="Events"
            /> */}
          </NavGroup>
          <NavGroup label={isCollapsed ? "" : "Analyze (Coming Soon)"}>
            <ReactRouterNavItem
              href={`/evals`}
              active={renderedMatch?.id === "evals"}
              icon={<HiOutlineClipboardCheck />}
              label="Evals"
              isCollapsed={isCollapsed}
            />
          </NavGroup>
        </Stack>
        <Box>
          <Stack spacing="1">
            {!isForcedCollapsed && <div onClick={toggleSidebar}>
              <ReactRouterNavItem
                active={false}
                icon={isCollapsed ? <HiOutlineChevronRight /> : <HiOutlineChevronLeft />}
                label={isCollapsed ? "Expand" : "Collapse"}
                isCollapsed={isCollapsed} />
            </div>}
            {/* <ReactRouterNavItem
              href="#todo"
              active={renderedMatch?.id === "settings"}
              subtle
              icon={<BiCog />}
              label="Settings"
            />
            <NavItem
              subtle
              icon={<BiBuoy />}
              label="Help & Support"
              endElement={<Circle size="2" bg="blue.400" />}
            /> */}
          </Stack>
        </Box>
      </Flex>
    </Box>
  );
};

export default Sidebar;
