import { useAuth } from "@clerk/clerk-react";
import { FC } from "react";
import { Navigate } from "react-router";

const RedirectToActiveOrganizationDashboard: FC = () => {
  const { orgSlug } = useAuth();

  if (!orgSlug) {
    throw new Error("No org slug found");
  }

  // Get the domain from the window. If the first subdomain
  // does not equal the org slug, then redirect to the org slug.
  // Keep the existing path.
  const domain = window.location.hostname;
  if (domain !== "localhost") {
    let path = window.location.pathname;
    if (path === "/") {
      path = "/text-generations"
    }
    const scheme = window.location.protocol;
    const subdomain = domain.split(".")[0];
    const rest = domain.split(".").slice(1).join(".");
    if (subdomain !== orgSlug) {
      window.location.replace(`${scheme}//${orgSlug}.${rest}${path}`);
      return null;
    }
  }


  // Otherwise we're in localhost or everything is fine,
  // so go to our dashboard.
  return <Navigate to={`/overview/utilization`} />;
};

export default RedirectToActiveOrganizationDashboard;
