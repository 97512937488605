import { FC } from "react";
import { useAuthQuery } from "../../../hooks/useAuthQuery";
import { createStackedSeries } from "../timeseries";
import { QueryProps, BaseChart } from "./BaseChart";


interface Props extends QueryProps {
    timeseriesQuery: string;
    chartName: string;
    chartDescription?: string;
    barColor?: string;
}

export const TimeseriesStackedBarChart: FC<Props> = ({ environment, promptName, timeseriesQuery, chartName, chartDescription }) => {
    const { data, isLoading } = useAuthQuery({
        endpoint: "/api/v1/timeseries",
        queryParams: {
            query: timeseriesQuery,
            environment: environment,
            promptName: promptName,
        }
    })

    // console.log(data?.data.length)
    const { series, xAxis, legend } = createStackedSeries({
        data: data?.data || [],
        chartType: 'bar',
    })

    const stackedOptions = {
        legend: legend,
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        animationEasing: 'cubicOut',
        animationDuration: 300,
        dataset: {
            source: series
        },
        xAxis: xAxis,
        yAxis: {
            type: 'value'
        },
        series: series,
        tooltip: {
            trigger: 'axis',
            confine: true,
        },
        itemStyle: {
            borderType: 'solid',
            borderColor: '#73c0de',
            shadowColor: '#5470c6',
            shadowBlur: 3,
        },
    };

    return <BaseChart title={chartName} chartOptions={stackedOptions} loading={isLoading} description={chartDescription} />
}