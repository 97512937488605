import { FC } from "react";
import { useAuthQuery } from "../../../../hooks/useAuthQuery";
import { TimeseriesApiData } from "../../timeseries";
import { BaseChart, QueryProps } from "../../components/BaseChart";

export const EventCountChart: FC<QueryProps> = ({ environment }) => {
    // TODO: handle errors
    const { data, isLoading } = useAuthQuery({
        endpoint: "/api/v1/timeseries",
        queryParams: {
            query: "all-events",
            environment: environment,
        }
    })

    const eventCountOptions = {
        grid: { top: 8, right: 8, bottom: 24, left: 36 },
        animationEasing: 'cubicOut',
        animationDuration: 300,
        xAxis: {
            type: 'time',
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: data?.data?.map((d: TimeseriesApiData) => [d._time, d.value]) || [],
                name: "Event Count",
                type: 'bar',
                smooth: true,
                itemStyle: {
                    borderRadius: [5, 5, 0, 0], // Specify the border radius
                    borderType: 'solid',
                },
            },
        ],
        tooltip: {
            trigger: 'axis',
        },
    };

    return <BaseChart title="Event Count" chartOptions={eventCountOptions} loading={isLoading} description={"This is a count of all events sent to ROSNIK per hour."} />
}