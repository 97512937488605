import { useAuth } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import { FC, useEffect, useState } from "react";
import { API_BASE_URL } from "../utils/constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import TimeframeFilterDropdown from "../components/TimeframeFilterDropdown";
import {
  InputGroup,
  InputLeftAddon,
  Input,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td, VStack, TableContainer, HStack, Tooltip, InputRightElement, Icon, Link
} from "@chakra-ui/react";
import { TimeRange } from "../utils/timerange";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { Link as ReactRouterLink } from "react-router-dom";

// TODO: figure out openapi
type RawRow = {
  startTime: string;
  journeyId: string;
  durationMs: number;
  userId: string;
  aiAction: string;
  aiModel: string;
  aiProvider: string;
  // TODO: type these
  aiRequestId: string;
  aiRequestPayload: any;
  aiRequestMessages: any;
  aiResponsePayload: any;
  aiResponseMessage: any;
  totalTokens: string;
  errorResponse: boolean;
  metadataAgg: any;
  promptName: string | null;
}

const GenerationsPage = () => {
  const { orgSlug, getToken } = useAuth();
  // @ts-ignore
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const hasTimeframe = (searchParams.has("start") && searchParams.has("end")) || searchParams.has("past");
  const [searchQuery, setSearchQuery] = useState<string>(searchParams.get("q") ?? "");

  // TODO: move over to useAuthQuery
  const { data, isLoading } = useQuery({
    queryKey: ["explore-text-generations", {
      start: searchParams.get("start"),
      end: searchParams.get("end"),
      q: searchParams.get("q"),
      past: searchParams.get("past"),
      orgSlug: orgSlug
    }],
    queryFn: async ({ queryKey }) => {
      // @ts-ignore -- need to figure out why this is not typing right
      const [_key, { start, end, q, past }] = queryKey
      const urlParams = new URLSearchParams()
      if (q) {
        urlParams.append("q", q)
      }
      if (past) {
        urlParams.append("past", past)
      }
      if (start) {
        urlParams.append("start", start)
      }
      if (end) {
        urlParams.append("end", end)
      }
      const result = await fetch(`${API_BASE_URL}/api/v1/explore/text-generations?${urlParams.toString()}`, {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
        }
      })
      return result.json()
    },
    // Only run this query once we have required search params.
    enabled: hasTimeframe,
  });

  useEffect(() => {
    // If we don't have a timeframe on render, set one.
    if (!hasTimeframe) {
      const searchParams = new URLSearchParams({ past: "4h" });
      navigate(`/text-generations?${searchParams.toString()}`)
    }
  }, [hasTimeframe])

  // If there isn't a search param query, reset the search query value.
  useEffect(() => {
    if (!searchParams.has("q")) {
      setSearchQuery("")
    }
  }, [searchParams])

  const onTimeframeChange = (v: TimeRange) => {
    let existingSearchParams;
    if (searchParams.has("q")) {
      existingSearchParams = { q: searchParams.get("q") ?? "" }
    }

    const params = new URLSearchParams(existingSearchParams);
    if (v.relativeKey) {
      params.append("past", v.relativeKey)
      setSearchParams(params)
      return;
    }

    params.append("start", v.start.toString())
    params.append("end", v.end.toString())
    setSearchParams(params)
  }

  const formattedData: RawRow[] = data
    ?
    data.results.map((d: any) => ({
      ...d,
      aiRequestPayload: JSON.parse(d.aiRequestPayload),
      aiRequestMessages: JSON.parse(d.aiRequestMessages),
      aiResponsePayload: JSON.parse(d.aiResponsePayload),
      aiResponseMessage: JSON.parse(d.aiResponseMessage),
      // TODO: type
      metadataAgg: d.metadataAgg.map((m: any) => JSON.parse(m)),
    }))
    : [];
  const whereFilter = data?.whereFilter;

  return (
    <VStack spacing={4} align={"start"}>

      {/* Search and filter */}
      <HStack align={"start"} w={"full"}>
        <InputGroup size="sm">
          <InputLeftAddon children="Search" />
          <Input
            type="text"
            placeholder="Tell us what you're looking for then press enter"
            rounded-left="0"
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyUpCapture={(e) => {
              if (e.key === 'Enter') {
                // Logic to execute when Enter is pressed
                searchParams.set("q", searchQuery)
                setSearchParams(searchParams.toString())
              }
            }}
          />
          {whereFilter && <InputRightElement>
            <Tooltip label={whereFilter} aria-label='Query filter tooltip'>
              <span><Icon as={HiOutlineInformationCircle} /></span>
            </Tooltip>
          </InputRightElement>}
        </InputGroup>
        <TimeframeFilterDropdown onChange={onTimeframeChange} defaultOption={{
          label: "Last 4 hours",
          value: "Last 4 hours"
        }} />
      </HStack>

      <EventsTable events={formattedData} isLoading={isLoading} />

    </VStack>

  );
};

interface EventsTableProps {
  events: RawRow[];
  isLoading: boolean;
}

const EventsTable: FC<EventsTableProps> = function ({ events, isLoading }) {
  if (isLoading) {
    return (
      <div className="px-4">
        <p>Loading...</p>
      </div>
    );
  }

  if (events.length === 0) {
    return (
      <div className="px-4">
        <p>No results found</p>
      </div>
    );
  }

  return (
    <TableContainer>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>Received At</Th>
            <Th>Environment</Th>
            <Th>Prompt Name</Th>
            <Th>Duration</Th>
            <Th>User ID</Th>
            <Th>AI Action</Th>
            <Th>AI Model</Th>
            <Th>AI Provider</Th>
            <Th>Total Tokens</Th>
            <Th>Prompt</Th>
            <Th>Text Generation</Th>
          </Tr>
        </Thead>
        <Tbody backgroundColor={"white"}>
          {events.map((e) => {
            let prompt;
            if (e.aiRequestMessages?.length > 0) {
              prompt = e.aiRequestMessages[0].content;
            } else {
              // This shouldn't happen but we have bad data
              // that's resolved in 0.0.30 in the SDK.
              prompt = e.aiRequestPayload?.prompt;
              // Sometimes this can be an array
              // Why wasn't this handlded in SQL?
              // Object { content: "¿Cómo puedo pagar la suscripción?", role: "user" }
              if (Array.isArray(prompt)) {
                prompt = prompt[0].content;
              }
            }

            if (prompt && prompt.length > 100) {
              prompt = prompt.substring(0, 100) + "...";
            }

            if (!prompt) {
              prompt = "undefined";
            }

            let generation;
            if (e.aiResponseMessage) {
              generation = e.aiResponseMessage.content;
            } else {
              // Make this more robust
              generation = e.aiResponsePayload?.choices[0].text;
            }

            if (generation && generation.length > 100) {
              generation = generation.substring(0, 100) + "...";
            }

            const backgroundColor = e.errorResponse ? "red.100" : "";

            return (
              <Tr key={e.aiRequestId} backgroundColor={backgroundColor}>
                <Td><Link as={ReactRouterLink} to={`/journey/${e.journeyId}#${e.aiRequestId}`}>{e.startTime}</Link></Td>
                <Td>{e.metadataAgg[0].environment ?? "-"}</Td>
                <Td>{e.promptName ?? "-"}</Td>
                <Td>{e.durationMs}</Td>
                <Td>{e.userId ?? "anonymous"}</Td>
                <Td>{e.aiAction}</Td>
                <Td>{e.aiModel}</Td>
                <Td>{e.aiProvider}</Td>
                <Td>{e.totalTokens}</Td>
                <Td>{prompt}</Td>
                <Td>{generation}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default GenerationsPage;
