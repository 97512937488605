import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, HStack, Text, useDisclosure, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, ModalHeader, useToast, Flex } from "@chakra-ui/react"
import { HiCode, HiOutlineClipboardCopy } from "react-icons/hi"
import { FunctionCall } from "../types"
import { FC, useState } from "react"
import { TooltipIconButton } from "../../../components/TooltipIconButton"
import JsonView from "@uiw/react-json-view"

interface Props {
    requestPayload?: { functions: FunctionCall[] }
}

export const FunctionCallAccordion: FC<Props> = ({ requestPayload }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedFunction, setSelectedFunction] = useState<FunctionCall | null>(null)
    const toast = useToast()

    if (!requestPayload?.functions?.length) return <></>

    return <>
        <Accordion w={"full"} allowToggle>
            <AccordionItem maxH={"230px"} overflowY={"scroll"}>
                <Flex position="sticky" top={0} zIndex={1} bg="white" shadow="sm" alignItems={"center"}>
                    <TooltipIconButton
                        tooltipText="Copy all function call JSON Schemas to clipboard."
                        icon={<HiOutlineClipboardCopy />}
                        onClick={(event) => {
                            event.stopPropagation()
                            navigator.clipboard.writeText(JSON.stringify(requestPayload.functions))
                            toast({
                                title: 'Copied to clipboard.',
                                description: `Copied all function call JSON Schemas to clipboard.`,
                                status: 'success',
                                duration: 1500,
                                isClosable: true,
                            })
                        }} />
                    <AccordionButton>
                        <Flex as="span" flex='1' textAlign='left' alignItems={"center"}>
                            Function Calls ({requestPayload?.functions?.length ?? 0})
                        </Flex>
                        <AccordionIcon />
                    </AccordionButton>
                </Flex>
                {requestPayload?.functions?.map((f: FunctionCall, i: number) => (
                    <AccordionPanel pb={1} key={`function-call-details-${i}`}>
                        <HStack>
                            <Text>{f.name}</Text>
                            <TooltipIconButton
                                tooltipText="Show function call details."
                                icon={<HiCode />}
                                onClick={() => {
                                    setSelectedFunction(f)
                                    onOpen()
                                }} />
                            <TooltipIconButton
                                tooltipText="Copy function call JSON Schema to clipboard."
                                icon={<HiOutlineClipboardCopy />}
                                onClick={() => {
                                    navigator.clipboard.writeText(JSON.stringify(f))
                                    toast({
                                        title: 'Copied to clipboard.',
                                        description: `Copied the JSON Schema of ${f.name}.`,
                                        status: 'success',
                                        duration: 1500,
                                        isClosable: true,
                                    })
                                }} />
                        </HStack>
                        <Text fontSize={"xs"} color={"gray.500"}>{f.description}</Text>
                    </AccordionPanel>))}
            </AccordionItem>
        </Accordion >
        <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{selectedFunction?.name}</ModalHeader>
                <ModalCloseButton />
                <ModalBody overflowX={"scroll"}>
                    <JsonView value={selectedFunction?.parameters} objectSortKeys displayDataTypes={false} shortenTextAfterLength={50} />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>
}