import { useLocation, useParams } from "react-router-dom";
import { VStack, Heading, HStack, Tag, Divider, Center, Text, Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import { AIGenerationFinish, AIGenerationStart, AIRequestCard, ImageTimelineCard, UserFeedbackTimelineCard, UserGoalTimelineCard, UserInteractionTimelineCard } from "./components/TimelineCard";
import { AIRequestFinish, AIRequestStart, JourneyMessage } from "./types";
import { useAuthQuery } from "../../hooks/useAuthQuery";
import { FunctionCallAccordion } from "./components/FunctionCallAccordion";
import { JourneySummaryTimeline } from "./components/JourneySummaryTimeline";

// TODO: use OpenAPI generator thing for types
const TimelinePage = () => {
    const { journeyId } = useParams();
    const { data, isLoading } = useAuthQuery({
        endpoint: `/api/v1/journeys/${journeyId}`
    })
    const messagesQueryData = useAuthQuery({
        endpoint: `/api/v1/journeys/${journeyId}/messages`
    })

    const metadataQueryData = useAuthQuery({
        endpoint: `/api/v1/journeys/${journeyId}/metadata`
    })

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            // Wait for potential renders and then try to find the element
            setTimeout(() => {
                const element = document.getElementById(location.hash.substring(1));
                if (element) element.scrollIntoView({ behavior: "smooth" });
            }, 0); // You might need to adjust this delay or even remove it depending on your case
        } else {
            // If there's no hash, you might want to scroll to the top
            window.scrollTo(0, 0);
        }
    }, [location]);

    if (!journeyId) {
        return <>Not found.</>
    }

    if (isLoading || metadataQueryData.isLoading) {
        return <>Loading...</>
    }

    if (!data) {
        return <>Something went wrong...</>
    }

    // @ts-ignore
    const processEvents = (data: any[]) => {
        // @ts-ignore
        const eventPairs = [];
        // @ts-ignore
        const unpairedEvents = [];
        const startEvents = {};
        const everyEvent: any = [];

        // @ts-ignore
        data.forEach(d => {
            everyEvent.push(d)
            switch (d.eventType) {
                case "ai.request.start":
                    // @ts-ignore
                    startEvents[d.eventId] = d;
                    break;
                case "ai.request.finish":
                    // @ts-ignore
                    if (d.aiRequestStartEventId && startEvents[d.aiRequestStartEventId]) {
                        // Call it an ai.request
                        // @ts-ignore
                        const eventPair = { eventType: "ai.request", startData: startEvents[d.aiRequestStartEventId], finishData: d, _time: Math.max(new Date(startEvents[d.aiRequestStartEventId]._time), new Date(d._time)) }
                        eventPairs.push(eventPair);
                    } else {
                        unpairedEvents.push(d);
                    }
                    break;
                // TODO: add more event types here
                default:
                    unpairedEvents.push(d);
                    break;
            }
        });

        // Merge and sort all events based on _time
        // TODO: include start events that don't have a finish event
        // @ts-ignore
        const allEvents = [...eventPairs, ...unpairedEvents].sort((a, b) => new Date(a._time) - new Date(b._time));
        return { allEvents, everyEvent };
    }

    const { allEvents } = processEvents(data);
    const eventIdToMessages = new Map();

    // Populate the map
    messagesQueryData.data?.forEach((eventMessage: JourneyMessage) => {
        const message = JSON.parse(eventMessage.message);
        // If eventIdToMessages doesn't have the event id, add it as a key and map to an array.
        // Otherwise, just push it to the array.
        if (!eventIdToMessages.has(eventMessage.firstEventId)) {
            eventIdToMessages.set(eventMessage.firstEventId, {
                parsedMessages: [message]
            });
        } else {
            eventIdToMessages.get(eventMessage.firstEventId)?.parsedMessages.push(message);
        }
    });

    const startDatetime = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
    }).format(new Date(metadataQueryData.data?.startTime))
    const endDatetime = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
    }).format(new Date(metadataQueryData.data?.endTime))

    return (
        <>
            <VStack align={"start"} pb={4}>
                <Heading size='sm'>Journey {journeyId}</Heading>
                <Text>{startDatetime} - {endDatetime}</Text>
                <HStack>
                    <Tag>steps:{allEvents.length.toString()}</Tag>
                    <Tag>duration:{metadataQueryData.data?.durationMs}</Tag>
                    <Tag>user:{metadataQueryData.data?.userId ?? "anonymous"}</Tag>
                    <Tag>environment:{metadataQueryData.data?.environment}</Tag>
                </HStack>
            </VStack>
            <HStack width={"full"} align="start">
                <VStack align={"start"} spacing={0} w={"75%"}>
                    {allEvents.map((event: any, index: number) => {
                        let cardElement;
                        let v;
                        let tags = [];
                        let showMessagesInfoTooltip = true;
                        switch (event.eventType) {
                            case "user.feedback.track":
                                cardElement = <UserFeedbackTimelineCard event={event} />
                                break;
                            case "user.interaction.track":
                                cardElement = <UserInteractionTimelineCard event={event} />
                                break;
                            case "user.goal.success":
                                cardElement = <UserGoalTimelineCard event={event} />
                                break;
                            case "demo.image":
                                cardElement = <ImageTimelineCard event={event} />
                                break;
                            case "ai.request":
                                let inputMessages = eventIdToMessages.get(event.startData.eventId)?.parsedMessages;
                                let rawInputMessages = JSON.parse(event.startData.aiRequestMessages);

                                // Don't show the tooltip if we have all the messages.
                                if (inputMessages && rawInputMessages && inputMessages.length === rawInputMessages.length) {
                                    showMessagesInfoTooltip = false;
                                }

                                // If we don't have messages, first let's see if we can render from raw.
                                if (!inputMessages) {
                                    inputMessages = JSON.parse(event.startData.aiRequestMessages)
                                    showMessagesInfoTooltip = false;
                                }
                                // If we still have nothing, then let's assume it's an instruct model.
                                // TODO: use the model name to determine which card to use
                                if (!inputMessages) {
                                    const parsedRequestPayload = JSON.parse(event.startData.aiRequestPayload);
                                    inputMessages = [{ content: parsedRequestPayload?.prompt, role: "system" }]
                                    showMessagesInfoTooltip = false;
                                }

                                let outputMessage = JSON.parse(event.finishData.aiResponseMessage);
                                // TODO: move to SQL?
                                if (!outputMessage) {
                                    outputMessage = {
                                        content: JSON.parse(event.finishData.aiResponsePayload)?.choices[0].text,
                                        role: "assistant"
                                    }
                                }
                                cardElement = <AIRequestCard
                                    startEvent={event?.startData}
                                    finishEvent={event?.finishData}
                                    _time={event._time}
                                    inputMessages={inputMessages}
                                    outputMessage={outputMessage}
                                    showMessagesInfoTooltip={showMessagesInfoTooltip}
                                />
                                break;
                            case "ai.request.start":
                                v = event as AIRequestStart;
                                let requestPayload = JSON.parse(event.aiRequestPayload)
                                let messages = eventIdToMessages.get(event.eventId)?.parsedMessages;
                                let rawMessages = JSON.parse(v.aiRequestMessages);

                                // Don't show the tooltip if we have all the messages.
                                if (messages && rawMessages && messages.length === rawMessages.length) {
                                    showMessagesInfoTooltip = false;
                                }

                                let apiUrl = new URL(JSON.parse(v.aiMetadata).openai_attributes.api_base)
                                // If we don't have messages, first let's see if we can render from raw.
                                if (!messages) {
                                    messages = JSON.parse(v.aiRequestMessages)
                                    showMessagesInfoTooltip = false;
                                }
                                // If we still have nothing, then let's assume it's an instruct model.
                                // TODO: use the model name to determine which card to use
                                if (!messages) {
                                    messages = [{ content: requestPayload?.prompt, role: "system" }]
                                    showMessagesInfoTooltip = false;
                                }

                                tags = [
                                    { model: v.aiModel },
                                    { provider: v.aiProvider },
                                    { temperature: requestPayload?.temperature },
                                    { endpoint: apiUrl.toString() },
                                    { "max-tokens": requestPayload?.max_tokens },
                                    { n: requestPayload?.n },
                                    { timeout: requestPayload?.timeout },
                                ]
                                if (v.promptName) {
                                    // @ts-ignore -- TODO: fix this
                                    tags.push({ "prompt-name": v.promptName })
                                }

                                cardElement = <AIGenerationStart
                                    v={v}
                                    d={event}
                                    messages={messages}
                                    tags={tags}
                                    showMessagesInfoTooltip={showMessagesInfoTooltip}
                                    footer={<FunctionCallAccordion requestPayload={requestPayload} />}
                                />
                                break;
                            case "ai.request.finish":
                                v = event as AIRequestFinish;
                                let completion = JSON.parse(v.aiResponseMessage);
                                let responsePayload = JSON.parse(event.aiResponsePayload)
                                // TODO: move to SQL?
                                if (!completion) {
                                    completion = {
                                        content: JSON.parse(event.aiResponsePayload)?.choices[0].text,
                                        role: "assistant"
                                    }
                                }

                                if (v.promptName) {
                                    tags.push({ "prompt-name": v.promptName })
                                }
                                if (responsePayload) {
                                    tags.push(...[{
                                        duration: Math.floor(event.aiResponseResponseMs).toString(),
                                        model: responsePayload?.model,
                                        "total-tokens": responsePayload?.usage?.total_tokens,
                                        "completion-tokens": responsePayload?.usage?.completion_tokens,
                                    }])
                                } else if (v.aiResponseErrorData !== "null") {
                                    const errorData = JSON.parse(v.aiResponseErrorData);
                                    if (errorData.json_body) {
                                        tags.push(...[
                                            { "error-code": errorData.json_body.error.code },
                                        ])
                                    } else if (errorData.user_message) {
                                        tags.push(...[
                                            { "error-message": errorData.user_message },
                                        ])
                                    }
                                }
                                cardElement = <AIGenerationFinish v={v} d={event} completion={completion} tags={tags} />
                                break;
                            default:
                                cardElement = null;
                        }

                        // If not the last item, return the cardElement along with the Divider
                        // If it's the last item, just return the cardElement
                        return (
                            <Flex key={`event-divider-${index}`} w={"full"} direction="column" align="start">
                                {cardElement}
                                {index !== allEvents.length - 1 && (
                                    <Center w="full">
                                        <Divider orientation='vertical' borderWidth={"1px"} height={"8"} />
                                    </Center>
                                )}
                            </Flex>
                        );
                    })}
                </VStack>
                <VStack align="start" height="90vh" pl={8}>
                    <JourneySummaryTimeline startDatetime={startDatetime} endDatetime={endDatetime} events={allEvents} />
                </VStack>
            </HStack >
        </>
    );

};



export default TimelinePage;
