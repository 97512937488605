import { FC } from "react";
import { useAuthQuery } from "../../../../hooks/useAuthQuery";
import { createSeries } from "../../timeseries";
import { BaseChart, QueryProps } from "../../components/BaseChart";

// Chart looks crazy. Going to hold for now.
export const FailedRequestsUrlStatus: FC<QueryProps> = ({ environment }) => {
    const { data, isLoading } = useAuthQuery({
        endpoint: "/api/v1/timeseries",
        queryParams: {
            query: "failed-requests-by-url-status",
            environment: environment,
        }
    })

    const seriesData = createSeries({
        data: data?.data || [],
        chartType: 'line',
        seriesOptions: {
            showSymbol: false,
            // tooltip: {
            //     valueFormatter: function (value: number) {
            //         const precision = 4
            //         const v = unit(value, 'ms')
            //         return format(v, precision)
            //     },
            // }
        },

    })
    const latencyOptions = {
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        animationEasing: 'cubicOut',
        animationDuration: 300,
        dataset: {
            source: seriesData
        },
        xAxis: {
            type: 'time',
        },
        yAxis: {
            type: 'value',

        },
        series: seriesData,
        tooltip: {
            trigger: 'axis',
            confine: true,
        },
        legend: {
            show: true,
            // You can also explicitly specify the data for the legend
            data: seriesData.map(serie => serie.name),
            type: 'scroll', // Enable scrolling for legends with many items
            orient: 'horizontal', // Arrange legend items horizontally
            animationDurationUpdate: 100
        }
    };
    return <BaseChart title="Failed Requets (hourly) by URL-Status" chartOptions={latencyOptions} loading={isLoading} />

}