// @ts-nocheck

import { useState, FC } from "react";
import { Box, } from "@chakra-ui/react";
import { Select, ActionMeta } from "chakra-react-select";
import { parseDateTimeInput, TimeRange } from "../utils/timerange";
import { RangeDatepicker } from "chakra-dayzed-datepicker";

interface TimeframeFilterOption extends OptionBase {
    label: string;
    value: string;
}

interface TimeframeFilterDropdownProps {
    /**
     * The currently selected timeframe.
     */
    onChange: (timeRange: TimeRange) => void;
    defaultOption: TimeframeFilterOption;
}

const TimeframeFilterDropdown: FC<TimeframeFilterDropdownProps> = ({ onChange, defaultOption }) => {
    const [dateRange, setDateRange] = useState("Past 4 hours");
    const [showRange, setShowRange] = useState(false);

    const [selectedOptions, setSelectedOptions] = useState<TimeframeFilterOption[]>([
        {
            label: "Past 5 Minutes",
            value: "Past 5 Minutes"
        },
        {
            label: "Past 15 Minutes",
            value: "Past 15 Minutes"
        },
        {
            label: "Past 30 Minutes",
            value: "Past 30 Minutes"
        },
        {
            label: "Past 1 Hour",
            value: "Past 1 Hour"
        },
        {
            label: "Past 4 Hours",
            value: "Past 4 Hours"
        },
        {
            label: "Past 1 Day",
            value: "Past 1 Day"
        },
        {
            label: "Past 2 Days",
            value: "Past 2 Days"
        },
        {
            label: "Past 1 Week",
            value: "Past 1 Week"
        },
        {
            label: "Past 1 Month",
            value: "Past 1 Month"
        },
        // {
        //     label: "Select Date",
        //     value: "Select Date"
        // },
    ]);
    const [selectedOption, setSelectedOption] = useState({
        label: "today",
        value: "today",
    })

    const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(), new Date()]);


    const timeOptions: TimeframeFilterOption[] = [
        {
            label: "Past 5 Minutes",
            value: "Past 5 Minutes"
        },
        {
            label: "Past 15 Minutes",
            value: "Past 15 Minutes"
        },
        {
            label: "Past 30 Minutes",
            value: "Past 30 Minutes"
        },
        {
            label: "Past 1 Hour",
            value: "Past 1 Hour"
        },
        {
            label: "Past 4 Hours",
            value: "Past 4 Hours"
        },
        {
            label: "Past 1 Day",
            value: "Past 1 Day"
        },
        {
            label: "Past 2 Days",
            value: "Past 2 Days"
        },
        {
            label: "Past 1 Week",
            value: "Past 1 Week"
        },
        {
            label: "Past 1 Month",
            value: "Past 1 Month"
        },
        // {
        //     label: "Select Date",
        //     value: "Select Date"
        // },

    ]

    const filterOptions = (
        candidate: { label: string; value: string; data: any },
        input: string
    ) => {
        return true
    };

    /**
     * Generate a TimeRange that we can use to query results
     * @param inputValue typed value by the user
     * @returns void -- updates state with a generated option by the parser
     */
    const handleInputChange = (inputValue: string) => {
        if (!inputValue) return

        const possibleTimeRange = parseDateTimeInput(inputValue);
        // If we have one, update our state.
        if (possibleTimeRange) {
            setSelectedOption({ label: possibleTimeRange.readable, value: possibleTimeRange.readable });
            // Replace our selected options to include the new one at the top.
            let simulateoption = [...timeOptions];
            simulateoption.unshift({ label: possibleTimeRange.readable, value: possibleTimeRange.readable });
            setSelectedOptions(simulateoption);
        }
    }

    /**
     * This is called when the user selects an option from the dropdown, or presses Enter after
     * typing in a custom one.
     * @param newValue This 
     * @param actionMeta 
     */
    const _onChange = (newValue: TimeframeFilterOption | null, actionMeta: ActionMeta<TimeframeFilterOption>) => {
        if (newValue?.value !== "Select Date") {
            const possibleTimeRange = parseDateTimeInput(newValue?.value);
            if (possibleTimeRange) {
                onChange(possibleTimeRange)
            }
        }

        if (newValue && (newValue.label === "Select Date")) {
            setShowRange(true);
        }
    }

    return (
        <Box minWidth="250">
            {!showRange &&
                <Select
                    defaultValue={defaultOption}
                    options={selectedOptions}
                    isSearchable
                    onChange={_onChange}
                    size="sm"
                    onInputChange={handleInputChange}
                    filterOption={filterOptions}
                    isOptionSelected={(o, v) => v.some((i) => i.value === o.value)}
                />
            }
            {showRange &&
                <RangeDatepicker
                    selectedDates={selectedDates}
                    onDateChange={setSelectedDates}
                />
            }
        </Box>
    );
};

export default TimeframeFilterDropdown;

