/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card } from "@chakra-ui/react";
import { OrganizationSwitcher } from "@clerk/clerk-react";
import type { FC } from "react";

const SelectOrganization: FC = function () {

  return (
    <div className="flex flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12">
      <Card
        className="w-full md:max-w-screen-sm [&>img]:hidden md:[&>img]:w-96 md:[&>img]:p-0 md:[&>*]:w-full md:[&>*]:p-16 lg:[&>img]:block"
      >
        <h1 className="mb-3 text-2xl font-bold dark:text-white md:text-3xl">
          Select an organization
        </h1>
        <p className="dark:text-white">
          Organizations are where your data will be namespaced and allow you to
          invite other team members.
        </p>
        <p className="dark:text-white">
          You don't have an active organization. Create a new one or select one
          you're a part of below.
        </p>
        {/* TODO: style. */}
        {/* TODO: accepting an invitation brings you to the previously active organization but not the one you just joined. */}
        <OrganizationSwitcher
          hidePersonal
          afterCreateOrganizationUrl="/"
          afterSwitchOrganizationUrl="/"
        />
        <Button color="primary">
          Back to Dashboard
        </Button>
      </Card>
    </div>
  );
};

export default SelectOrganization;
