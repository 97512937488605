export interface TimeseriesApiData {
    _time: string;
    value: number;
    groupKey: string;
}

export const createSeries = ({ data, chartType, seriesOptions }: {
    data: TimeseriesApiData[],
    chartType: "line",
    // Additional echarts options to apply to each series set
    // TODO: type this if possible.
    seriesOptions?: { [key: string]: any }
}) => {
    // Group data by the groupKey
    const groupedData = data.reduce((acc: { [key: string]: TimeseriesApiData[] }, entry: TimeseriesApiData) => {
        const groupKey = entry.groupKey;
        if (!acc[groupKey]) {
            acc[groupKey] = [];
        }
        acc[groupKey]?.push(entry);
        return acc;
    }, {});

    // Convert grouped data into ECharts series format
    return Object.keys(groupedData).map(key => {
        const items = groupedData[key];
        return {
            name: key,
            type: chartType,
            data: items?.map((item: TimeseriesApiData) => [item._time, item.value]),
            ...seriesOptions // Spread additional ECharts options
        };
    });
}


export const createStackedSeries = ({ data, chartType, seriesOptions }: { data: TimeseriesApiData[], chartType: "bar", seriesOptions?: { [key: string]: string; } }) => {
    // Group by timestamp and groupKey, then sum the values
    const groupedData: { [key: string]: { [subkey: string]: number } } = {};

    data.forEach((entry: TimeseriesApiData) => {
        const timestamp = entry._time;
        const value = entry.value;
        const groupKey = entry.groupKey;

        groupedData[timestamp] = groupedData[timestamp] || {};
        // @ts-expect-error -- this is a valid key
        groupedData[timestamp][groupKey] = groupedData[timestamp][groupKey] || 0;
        // @ts-expect-error -- this is a valid key
        groupedData[timestamp][groupKey] += value;

        if (!groupedData[timestamp]) {
            groupedData[timestamp] = {};
        }

        // @ts-expect-error -- this is a valid key
        if (!groupedData[timestamp][groupKey]) {
            // @ts-expect-error -- this is a valid key
            groupedData[timestamp][groupKey] = 0;
        }

        // @ts-expect-error -- this is a valid key
        groupedData[timestamp][groupKey] += value;
    });

    // Convert grouped data to ECharts series format
    // Only get groupKeys that are unique and not-null
    let uniqueGroupKeys = [...new Set(data.map(entry => entry.groupKey))];
    const xAxisData = [...new Set(data.map(item => item._time))];

    uniqueGroupKeys = uniqueGroupKeys.filter(groupKey => {
        // @ts-expect-error -- this is a valid key
        return Object.values(groupedData).some(values => values[groupKey] > 0);
    });

    // Map unique group keys to series
    const stackedSeries = uniqueGroupKeys.map(groupKey => {
        const seriesData = Object.entries(groupedData).map(([timestamp, valuesByGroup]) => {
            return [timestamp, valuesByGroup[groupKey] ?? 0];
        });

        // Filter out series data where all values are 0
        // @ts-expect-error -- this is a number
        if (!seriesData.some(([_, value]) => value > 0)) {
            return null;
        }

        return {
            name: groupKey,
            type: chartType,
            stack: 'Total',
            data: seriesData,
            ...seriesOptions // Spread additional ECharts options
        };
    }).filter(series => series !== null); // Remove any null series created by the filtering

    return {
        legend: {
            data: uniqueGroupKeys,
            type: 'scroll', // Enable scrolling for legends with many items
            orient: 'horizontal', // Arrange legend items horizontally
            animationDurationUpdate: 100
        },
        series: stackedSeries,
        xAxis: {
            data: xAxisData, // Set the unique timestamps here
            boundaryGap: false,
            type: 'time'
        }
    };
}