import { Box, HStack, Tooltip, VisuallyHidden } from "@chakra-ui/react";
import { FC, ReactElement, ReactNode } from "react";
import { BsCaretRightFill } from "react-icons/bs";
import { Link } from "react-router-dom";

interface NavItemProps {
    href?: string;
    label?: string;
    subtle?: boolean;
    active?: boolean;
    icon: ReactElement;
    endElement?: ReactElement;
    children?: ReactNode;
    isCollapsed?: boolean;
}

export const ReactRouterNavItem: FC<NavItemProps> = ({ active, subtle, icon, children, label, endElement, href, isCollapsed }) => {
    const WrapperComponent = href ? Link : Box;

    const navComponent = <HStack
        as={WrapperComponent}
        h="10"
        w="full"
        px="3"
        py="2"
        cursor="pointer"
        userSelect="none"
        rounded="md"
        transition="all 0.2s"
        bg={active ? "gray.700" : undefined}
        _hover={{ bg: "gray.700" }}
        _active={{ bg: "gray.600" }}
        align="center"
        spacing={isCollapsed ? "0" : "3"}
        // Spread the 'to' prop only if href is provided
        {...(href ? { to: href } : {})}
    >
        <Box fontSize="lg" color={active ? "currentcolor" : "gray.400"}>
            {icon}
        </Box>
        {label && (
            <Box flex="1" fontWeight="inherit" color={subtle ? "gray.400" : undefined}>
                {isCollapsed ? <VisuallyHidden>{label}</VisuallyHidden> : label}
            </Box>
        )}
        {endElement && !children && <Box>{endElement}</Box>}
        {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
    </HStack>

    if (isCollapsed) {
        return (
            <Tooltip label={label} placement="right" hasArrow>
                {navComponent}
            </Tooltip>
        );
    }

    return navComponent;
};
